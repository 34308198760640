import { useEffect, useRef } from 'react';
import {
  Combobox,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
  Field,
  Label,
} from '@headlessui/react';
import { SearchIcon } from '@heroicons/react/outline';
import {
  LoaderFunction,
  useLoaderData,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { components, paths } from '@mosey/api-types';
import { api } from '../utils/fetchApi';
import { requirementPath } from '../utils/paths';

type SearchResult = components['schemas']['SearchResult'];
type SearchResults =
  paths['/api/search']['post']['responses']['200']['content']['application/json'];

export const loader: LoaderFunction = async ({ request }) => {
  const url = new URL(request.url);
  const query = url.searchParams.get('q');

  if (query) {
    return api({
      url: `/api/search`,
      method: 'POST',
      body: { query },
    });
  }

  return [];
};

export const Component = () => {
  const inputRef = useRef<HTMLInputElement>(null);
  const results = useLoaderData() as SearchResults;
  const [searchParams, setSearchParams] = useSearchParams();
  const query = searchParams.get('q');
  const navigate = useNavigate();

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const handleQueryChange = (query: string) => {
    if (query) {
      searchParams.set('q', query);
    } else {
      searchParams.delete('q');
    }

    setSearchParams(searchParams);
  };

  return (
    <div className="my-32 space-y-8">
      <h1 className="text-center text-4xl font-bold tracking-tight text-zinc-800">
        Search
      </h1>

      <Field>
        <Label className="sr-only">Search</Label>
        <Combobox<SearchResult | string | null>
          immediate
          value={query}
          onChange={(selected: SearchResult) => {
            if (selected) {
              navigate(requirementPath(selected.requirement_data_id));
            }
          }}
        >
          <div className="relative mx-auto w-full max-w-96">
            <ComboboxInput
              ref={inputRef}
              className="block w-full rounded border-1 border-gray-300 py-2 pl-9 pr-3 text-gray-500 focus:border-1 focus:border-gray-300 focus:outline-[3px] focus:-outline-offset-[3px] focus:outline-teal-600 focus:ring-0"
              onChange={(event) => handleQueryChange(event.target.value)}
            />
            <SearchIcon className="pointer-events-none absolute left-3 top-1/2 size-4 -translate-y-1/2 text-gray-500" />
          </div>

          <ComboboxOptions
            anchor={{ to: 'bottom', gap: 16, padding: 16 }}
            className="w-96 overflow-y-auto overflow-x-hidden rounded-md bg-white shadow-lg ring-1 ring-black/5 empty:invisible focus:outline-none"
          >
            {results.map((result) => (
              <ComboboxOption
                key={result.requirement_data_id}
                value={result}
                className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
              >
                {result.term}
              </ComboboxOption>
            ))}
          </ComboboxOptions>
        </Combobox>
      </Field>
    </div>
  );
};
