import { Interstitial, InterstitialProps } from '../common/Interstitial';
import { AssessmentGraphic } from './AssessmentGraphic';

type AssessmentInterstitialProps = Omit<
  InterstitialProps,
  'children' | 'title' | 'description'
> &
  Partial<Pick<InterstitialProps, 'title' | 'description' | 'fullHeight'>>;

export const AssessmentInterstitial = ({
  title = 'Answer questions',
  description = 'Every business is unique. Your answers to a handful of questions will generate setup tasks tailored to your situation.',
  ...props
}: AssessmentInterstitialProps) => {
  return (
    <Interstitial title={title} description={description} {...props}>
      <div className="-mb-10 max-w-[860px]">{AssessmentGraphic}</div>
    </Interstitial>
  );
};
