import { PageHeader } from '@mosey/components/page-header/PageHeader';
import { Button } from '@mosey/components/buttons/Button';
import { TextLink } from '@mosey/components/navigation/TextLink';
import { usePolicyCategory } from '../hooks/usePolicyCategory';
import {
  ActionFunctionArgs,
  Navigate,
  redirect,
  useFetcher,
  useParams,
} from 'react-router-dom';
import { BackButton, TextAreaField } from '../../../components';
import { FormProvider, useForm } from 'react-hook-form';
import { PolicyGroupMainContent } from '../components/PolicyGroupMainContent';
import { api } from '../../../utils/fetchApi';

type FormData = {
  content: string | undefined;
};

export const CustomCategoryPolicy = () => {
  const { sectionId, categoryId } = useParams();
  const { category } = usePolicyCategory(sectionId!, categoryId!);
  const form = useForm<FormData>({
    defaultValues: {
      content: category?.customPolicy?.content.main[0].content || '',
    },
  });
  const { Form, state, submit } = useFetcher();

  const sectionPoliciesUrl = `/handbook/section/${sectionId}/policies`;

  const onSubmit = (data: FormData) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    submit({ policyId: category?.customPolicy?.id, ...data } as any, {
      method: 'POST',
      encType: 'application/json',
    });
  };

  if (!category) {
    return <Navigate to={sectionPoliciesUrl} replace />;
  }
  return (
    <>
      <PageHeader>
        <BackButton to={sectionPoliciesUrl} />
      </PageHeader>
      <FormProvider {...form}>
        <Form
          className="max-w-3xl space-y-8 px-8 py-6"
          onSubmit={form.handleSubmit(onSubmit)}
        >
          <div className="space-y-1 py-2">
            <h1 className="text-xl font-semibold">
              Add a company-wide {category.title}
            </h1>
            <p className="text-zinc-800">
              Any location-specific policies that apply to an employee will be
              displayed alongside the company-wide policy.
            </p>
          </div>
          <TextAreaField
            name="content"
            label="Policy Text"
            rows={5}
            reactFormConfig={{
              required: 'Policy Text is required',
              minLength: {
                message: 'Policy Text must be at least 3 characters',
                value: 3,
              },
            }}
            error={form.formState.errors.content}
            inputClassName="min-h-[117px]"
          />
          <div>
            <p className="pb-2 font-semibold text-zinc-800">
              Location-specific policies
            </p>
            <div className="flex flex-col gap-4 ">
              {category.groups.map(({ policies, regions }, index) => (
                <PolicyGroupMainContent
                  key={index}
                  policies={policies}
                  regions={regions}
                />
              ))}
            </div>
          </div>
          <div className="flex items-center gap-6">
            <Button type="submit" isLoading={state !== 'idle'}>
              Save & continue
            </Button>
            <TextLink
              to={sectionPoliciesUrl}
              variant="secondary"
              skipInk={false}
            >
              Cancel
            </TextLink>
          </div>
        </Form>
      </FormProvider>
    </>
  );
};

CustomCategoryPolicy.action = async ({
  request,
  params: { categoryId, sectionId },
}: ActionFunctionArgs) => {
  const { content, policyId } = await request.json();
  const body = {
    content,
  };
  if (policyId) {
    await api({
      method: 'PUT',
      url: `/api/handbook/policies/${policyId}`,
      body,
    });
  } else {
    await api({
      method: 'POST',
      url: `/api/handbook/policy_categories/${categoryId}/policies`,
      body,
    });
  }
  return redirect(`/handbook/section/${sectionId}/policies`);
};

CustomCategoryPolicy.loader = async ({ params }: ActionFunctionArgs) => {
  return api({
    url: `/api/handbook/policy_section/${params.sectionId}/attribute_values`,
    method: 'GET',
  });
};
