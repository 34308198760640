import { useRouteLoaderData } from 'react-router-dom';
import { HandbookDataLoader, PolicySection } from '../types';

export function usePolicySections() {
  const { policies, sections } = useRouteLoaderData(
    'handbook',
  ) as HandbookDataLoader;

  const sectionLookup: Record<string, PolicySection> = sections.reduce(
    (acc, section) => {
      acc[section.id] = section;
      return acc;
    },
    {} as Record<string, PolicySection>,
  );
  const unusedSections = sections.filter((section) =>
    policies.every((policy) => policy.policy_section.id !== section.id),
  );
  return { allSections: sections, sectionLookup, unusedSections };
}
