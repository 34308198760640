import { PolicyMainContent } from './types';
import { PolicyContentRenderer } from './PolicyContentRenderer';

type RegionScopedContentProps = {
  mainContent: Array<PolicyMainContent>;
  regions: Array<string>;
};

export const RegionScopedContent = ({
  mainContent,
  regions,
}: RegionScopedContentProps) => {
  return (
    <div>
      {regions.length > 0 && (
        <h3 className="pb-2 text-sm font-normal text-zinc-600">
          {`For employees in ${
            regions.length > 2
              ? regions.slice(0, -1).join(', ') +
                ', and ' +
                regions[regions.length - 1]
              : regions.join(' and ')
          }:`}
        </h3>
      )}

      {mainContent.map((item, index: number) => (
        <PolicyContentRenderer key={index} content={item} />
      ))}
    </div>
  );
};
