import { useState } from 'react';
import {
  useNavigate,
  useLocation,
  LoaderFunction,
  useLoaderData,
  useMatch,
  useSearchParams,
} from 'react-router-dom';
import { PageHeader } from '@mosey/components/page-header/PageHeader';
import { PageTitle } from '@mosey/components/page-header/PageTitle';
import { RequirementsTable, QuickFilters } from '../components';
import {
  FilterRequirementsBy,
  filterRequirements,
  getCountForFilter,
  isActionableRequirement,
} from '../utils/filter';
import { computedRequirementCounts } from '../utils/stats';
import { sortRequirements } from '../utils/sort';
import { RequirementOverview } from '../types';
import { useUser } from '../hooks/useUser';
import { api } from '../utils/fetchApi';

export const loader: LoaderFunction = async () => {
  return api({
    url: '/api/requirements/overviews',
    method: 'GET',
  });
};

export const Component = () => {
  const { results } = useLoaderData() as { results: RequirementOverview[] };
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const user = useUser();
  const isAll = useMatch('/tasks/all');
  const viewName = isAll ? 'Tasks - All' : 'Tasks - Assigned to me';
  const filters = searchParams.get('filters')?.split(',') || [];

  let requirements = results.filter((req) => {
    // Always hide deferred, blocked, virtual instances from the tasks view
    // because we only want items they can take action on
    if (!isActionableRequirement(req)) return false;

    if (isAll) {
      return true;
    }

    return req.assigned_user?.id === user.id;
  });

  let filter = FilterRequirementsBy.All;
  if (filters && filters.length > 0) {
    // Note: Only supporting one filter for now, but don't want to
    // rename later since it's in the query params and we don't want
    // to break user links
    filter = filters[0] as FilterRequirementsBy;
  }
  const [reqFilter, setFilter] = useState(filter);
  const computedReqCounts = computedRequirementCounts(requirements);

  requirements = sortRequirements(requirements);
  requirements = filterRequirements(requirements, reqFilter);

  const handleStatusFilterClick = (f: FilterRequirementsBy): void => {
    setFilter(f);
    navigate(`${pathname}?filters=${f.toLowerCase()}`);
  };

  const allowedFilters = [
    FilterRequirementsBy.All,
    FilterRequirementsBy.New,
    FilterRequirementsBy.Upcoming,
    FilterRequirementsBy.Overdue,
    FilterRequirementsBy.Done,
  ];

  return (
    <>
      <PageHeader>
        <PageTitle>{viewName}</PageTitle>

        <div className="mt-2.5">
          <QuickFilters
            items={allowedFilters.map((f) => {
              return {
                label: f,
                count: getCountForFilter(computedReqCounts, f),
                isSelected: reqFilter === f,
              };
            })}
            onClick={handleStatusFilterClick}
          />
        </div>
      </PageHeader>

      <RequirementsTable requirements={requirements} border={false} />
    </>
  );
};
