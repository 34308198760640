import {
  LoaderFunctionArgs,
  ShouldRevalidateFunction,
  matchPath,
  redirect,
} from 'react-router-dom';
import { api, apiBatch } from '../../../utils/fetchApi';
import { HandbookDataLoader } from '../types';

export const policySectionFormsLoader = async ({
  params,
  request,
}: LoaderFunctionArgs) => {
  const { sectionId } = params;
  const response = await api({
    url: `/api/handbook/policy_section/${sectionId}/forms`,
    method: 'GET',
  });
  const forms = await response.json();
  const location = new URL(request.url);

  if (
    !matchPath(
      { path: `/handbook/section/${sectionId}/configure`, end: false },
      location.pathname,
    )
  ) {
    if (forms.length > 0) {
      return redirect(`/handbook/section/${sectionId}/configure`);
    }
  } else if (forms.length === 0) {
    return redirect(`/handbook/section/${sectionId}/policies`);
  }
  return forms;
};

function isPolicySectionDetailsRoute(pathname: string) {
  return (
    matchPath('/handbook/section/:sectionId/policies', pathname) ||
    matchPath('/handbook/section/:sectionId/categories/:categoryId', pathname)
  );
}

export const shouldPolicySectionFormsRevalidate: ShouldRevalidateFunction = ({
  currentUrl,
  nextUrl,
  currentParams,
  nextParams,
  defaultShouldRevalidate,
}) => {
  if (
    currentParams.sectionId === nextParams.sectionId &&
    isPolicySectionDetailsRoute(currentUrl.pathname) &&
    isPolicySectionDetailsRoute(nextUrl.pathname)
  ) {
    return false;
  }
  return defaultShouldRevalidate;
};

export const handbookLoader = async ({ request }: LoaderFunctionArgs) => {
  return await apiBatch({
    draft: {
      method: 'GET',
      url: '/api/handbook/draft',
      onError(error) {
        if (error.status === 404) {
          const url = new URL(request.url);
          if (matchPath('/handbook/setup/*', url.pathname)) {
            return null;
          } else {
            throw redirect('/handbook/setup');
          }
        } else if (error.status === 403) {
          throw redirect('/handbook/request');
        } else {
          throw error;
        }
      },
    },
    published: {
      method: 'GET',
      url: '/api/handbook/published',
      onError(error) {
        if (error.status === 404) {
          return null;
        } else if (error.status === 403) {
          throw redirect('/handbook/request');
        } else {
          throw error;
        }
      },
    },
    policies: {
      url: '/api/handbook/policies',
      method: 'GET',
      onError: () => [],
    },
    stats: {
      method: 'GET',
      url: '/api/handbook/stats',
      onError(error) {
        if (error.status === 404) {
          return {
            acknowledgements: {
              outdated: 0,
              signed: 0,
              pending: 0,
            },
            handbooks: {
              published: 0,
            },
          } as HandbookDataLoader['stats'];
        } else if (error.status === 403) {
          throw redirect('/handbook/request');
        } else {
          throw error;
        }
      },
    },
    sections: {
      method: 'GET',
      url: '/api/handbook/policy_sections',
      onError: () => [],
    },
  });
};
