import { useRef } from 'react';
import { clsx } from 'clsx';
import { Link, useSearchParams } from 'react-router-dom';
import { Menu, MenuButton, MenuItems } from '@headlessui/react';
import { useTooltipTriggerState } from '@react-stately/tooltip';
import { useTooltipTrigger, useTooltip } from '@react-aria/tooltip';
import { mergeProps } from '@react-aria/utils';
import { MenuTransition } from '@mosey/components/menus/common/MenuTransition';
import { MenuItem } from '@mosey/components/menus/DropdownMenu';
import { OfficeIcon, SelectIcon } from '@mosey/components/Icons';
import * as config from '../../settings/config';
import { useUser } from '../../hooks/useUser';
import { NavTooltip } from '@mosey/components/navigation/NavTooltip';

interface UserMenuProps {
  isNavClosed: boolean;
}

export const UserMenu = ({ isNavClosed }: UserMenuProps) => {
  const tooltipState = useTooltipTriggerState({ delay: 0, closeDelay: 0 });
  const menuButtonRef = useRef<HTMLButtonElement>(null);
  const { triggerProps, tooltipProps } = useTooltipTrigger(
    {},
    tooltipState,
    menuButtonRef,
  );
  const { tooltipProps: ariaTooltipProps } = useTooltip(
    tooltipProps,
    tooltipState,
  );
  const buttonRect = menuButtonRef.current?.getBoundingClientRect();
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    legal_entities: legalEntities,
    legal_entity: currentLegalEntity,
    role,
  } = useUser();

  return (
    <Menu as="div" className="relative z-[1000] px-2 pb-[19px]">
      <MenuButton
        {...mergeProps(triggerProps, { ref: menuButtonRef })}
        className="z-[1000] w-full rounded-md px-[14px] py-2 font-semibold text-zinc-700 outline-none -outline-offset-2 transition-nav duration-300 ease-in-out hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-rose-700"
        aria-describedby="legal-entity-switch-description"
      >
        <div className="flex items-center">
          <OfficeIcon
            className={clsx(
              'shrink-0 translate-x-0.5 text-gray-400 transition-nav duration-300 ease-in-out',
              isNavClosed && 'size-5',
              !isNavClosed && 'size-0',
            )}
          />

          <div
            className={clsx(
              'mr-1 w-fit overflow-hidden truncate text-left font-bold transition-nav duration-300 ease-in-out',
              isNavClosed && '-ml-5 w-0 opacity-0',
              !isNavClosed && 'ml-0 w-[155px]',
            )}
          >
            {currentLegalEntity.name}
          </div>

          <SelectIcon
            className={clsx(
              'size-4 shrink-0 text-gray-400 transition-nav duration-300 ease-in-out',
              isNavClosed && 'opacity-0',
            )}
          />
        </div>
      </MenuButton>

      {tooltipState.isOpen && buttonRect && isNavClosed && (
        <NavTooltip {...ariaTooltipProps} y={20}>
          <div className="font-semibold">{currentLegalEntity.name}</div>
          <div className="font-normal">Switch entity</div>
        </NavTooltip>
      )}

      <span id="legal-entity-switch-description" className="sr-only">
        This menu allows you to switch between legal entities.
      </span>

      <MenuTransition>
        <MenuItems
          anchor={{ to: 'bottom start', gap: 8, padding: 8 }}
          className="z-[1000] w-48 rounded-md bg-white py-1 shadow-lg ring-1 ring-black/5"
        >
          {legalEntities.map((legalEntity) => (
            <MenuItem
              as="button"
              key={legalEntity.id}
              aria-label={`Switch to ${legalEntity.name}`}
              onClick={() => {
                searchParams.set('legal_entity_id', legalEntity.id);
                setSearchParams(searchParams);
              }}
              aria-pressed={legalEntity.id === currentLegalEntity.id}
              selected={legalEntity.id === currentLegalEntity.id}
            >
              {legalEntity.name}
            </MenuItem>
          ))}

          <hr />

          {role === 'admin' && (
            <MenuItem as={Link} to={config.ADMIN_BASE_URL} target="_blank">
              Admin
            </MenuItem>
          )}

          <MenuItem as={Link} to="/logout">
            Sign Out
          </MenuItem>
        </MenuItems>
      </MenuTransition>
    </Menu>
  );
};
