import { FunctionComponent } from 'react';
import { clsx } from 'clsx';

export enum ProgressStatus {
  Complete = 'Complete',
  NotStarted = 'Not Started',
  InProgress = 'In Progress',
  Overdue = 'Overdue',
}

export const statusFromPercentComplete = (complete: number): ProgressStatus => {
  switch (complete) {
    case 0:
      return ProgressStatus.NotStarted;
    case 100:
      return ProgressStatus.Complete;
    default:
      return ProgressStatus.InProgress;
  }
};

type ProgressBarProps = {
  complete: number;
  bgColor: string;
  bgHighlightColor: string;
};

export const ProgressBar: FunctionComponent<ProgressBarProps> = ({
  complete,
  bgColor,
  bgHighlightColor,
}) => {
  const completeFormatted = `${complete}%`;

  return (
    <div
      className={clsx('mb-2 flex h-2 overflow-hidden rounded text-xs', bgColor)}
    >
      <div
        style={{ width: completeFormatted }}
        className={clsx(
          'flex flex-col justify-center whitespace-nowrap rounded text-center text-white shadow-none transition-[width]',
          bgHighlightColor,
        )}
      />
    </div>
  );
};
