import { PageHeader } from '@mosey/components/page-header/PageHeader';
import { Button } from '@mosey/components/buttons/Button';
import { TextLink } from '@mosey/components/navigation/TextLink';
import {
  ActionFunctionArgs,
  Navigate,
  redirect,
  useFetcher,
  useParams,
} from 'react-router-dom';
import { BackButton, TextAreaField } from '../../../components';
import { FormProvider, useForm } from 'react-hook-form';
import { api } from '../../../utils/fetchApi';
import { TextField } from '@mosey/components/forms/TextField';
import { usePolicy } from '../hooks/usePolicy';
import { usePolicySections } from '../hooks/usePolicySections';

type FormData = {
  title: string | undefined;
  content: string | undefined;
};

export const CustomPolicy = () => {
  const { sectionId, policySlug } = useParams();
  const { sectionLookup, unusedSections } = usePolicySections();
  const section = sectionLookup[sectionId!];
  const isFirstPolicy = unusedSections.find((s) => s.id === sectionId);
  const backUrl = isFirstPolicy
    ? '/handbook/overview'
    : `/handbook/section/${sectionId}/policies`;

  const { policy: policyItem } = usePolicy(sectionId!, policySlug!);
  const policy = policyItem?.policy;

  const form = useForm<FormData>({
    defaultValues: {
      title: policy?.title || '',
      content: policy?.content.main[0].content || '',
    },
  });
  const { Form, state, submit } = useFetcher();

  const onSubmit = (data: FormData) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    submit({ policyId: policy?.id, ...data } as any, {
      method: 'POST',
      encType: 'application/json',
    });
  };

  if (!section) {
    return <Navigate to="/handbook/overview" replace />;
  }
  if ((policy && !policy.is_custom_policy) || (policySlug && !policy)) {
    return <Navigate to={backUrl} replace />;
  }

  return (
    <>
      <PageHeader>
        <BackButton to={backUrl} />
      </PageHeader>
      <FormProvider {...form}>
        <Form
          className="max-w-3xl space-y-8 px-8 py-6"
          onSubmit={form.handleSubmit(onSubmit)}
        >
          <div className="space-y-1 py-2">
            <h1 className="text-xl font-semibold">
              {section.title} - {policy ? 'Edit policy' : 'Add a policy'}
            </h1>
            {isFirstPolicy && (
              <p className="text-zinc-700">
                Add a policy to create this section
              </p>
            )}
          </div>
          <div>
            <TextField
              name="title"
              label="Policy Title"
              reactFormConfig={{
                required: 'Policy Title is required',
                minLength: {
                  message: 'Policy Title must be at least 3 characters',
                  value: 3,
                },
              }}
              error={form.formState.errors.title}
            />
            <TextAreaField
              name="content"
              label="Policy Text"
              rows={5}
              reactFormConfig={{
                required: 'Policy Text is required',
                minLength: {
                  message: 'Policy Text must be at least 3 characters',
                  value: 3,
                },
              }}
              error={form.formState.errors.content}
              inputClassName="min-h-[117px]"
            />
          </div>
          <div className="flex items-center gap-6">
            <Button type="submit" isLoading={state !== 'idle'}>
              {isFirstPolicy ? 'Create section' : 'Save & continue'}
            </Button>
            <TextLink to={backUrl} variant="secondary" skipInk={false}>
              Cancel
            </TextLink>
          </div>
        </Form>
      </FormProvider>
    </>
  );
};

CustomPolicy.action = async ({
  request,
  params: { sectionId },
}: ActionFunctionArgs) => {
  const { content, title, policyId } = await request.json();
  const body = {
    content,
    title,
    policy_section_id: sectionId,
  };
  if (policyId) {
    await api({
      method: 'PUT',
      url: `/api/handbook/policies/${policyId}`,
      body,
    });
  } else {
    await api({
      method: 'POST',
      url: `/api/handbook/policies`,
      body,
    });
  }
  return redirect(`/handbook/section/${sectionId}/policies`);
};
