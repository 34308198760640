import { Button } from '@mosey/components/buttons/Button';
import { TextLink } from '@mosey/components/navigation/TextLink';
import welcomeImg from '../../assets/handbook-welcome.png';
import { api } from '../../utils/fetchApi';
import { useFetcher } from 'react-router-dom';

export const RequestHandbookAccess = () => {
  const { Form, state, data } = useFetcher();
  const hasRequestedAccess = data?.success;
  return (
    <section className="flex min-h-[calc(100vh-64px)] flex-col items-center justify-center p-8">
      <img src={welcomeImg} alt="" className="mb-10 w-full max-w-[625px]" />
      <h1 className="text-center text-2xl font-bold">
        {hasRequestedAccess
          ? 'Access requested'
          : 'An easier way to manage employee handbooks'}
      </h1>
      <p className="mt-1 text-center text-zinc-800">
        {hasRequestedAccess ? (
          <>
            Our team will reach out shortly.{' '}
            <TextLink
              to="https://mosey.com/platform/handbooks/"
              target="_blank"
              variant="secondary"
              skipInk={false}
              skipIcon
            >
              Learn more about employee handbooks.
            </TextLink>
          </>
        ) : (
          'Automate the end-to-end handbook process for a compliant handbook across all 50 states.'
        )}
      </p>
      {!hasRequestedAccess && (
        <Form className="mt-6 flex items-center gap-4" method="POST">
          {!hasRequestedAccess && (
            <Button type="submit" isLoading={state !== 'idle'}>
              Request access
            </Button>
          )}
          <p>
            or{' '}
            <TextLink
              to="https://mosey.com/platform/handbooks/"
              target="_blank"
              variant="secondary"
              skipInk={false}
              skipIcon
            >
              learn more
            </TextLink>
          </p>
        </Form>
      )}
    </section>
  );
};

RequestHandbookAccess.action = async () => {
  await api({
    url: '/api/handbook/request-access',
    method: 'POST',
  });
  return {
    success: true,
  };
};
