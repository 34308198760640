import { useState, useRef, useEffect } from 'react';
import { PolicyContentType } from '@mosey/components/handbook/types';
import { PolicyMarkdown } from './PolicyMarkdown';

type ExpandablePolicyMarkdownProps = {
  content: string;
};

const MAX_HEIGHT_PX = 64; // max-h-16

export const ExpandablePolicyMarkdown = ({
  content,
}: ExpandablePolicyMarkdownProps) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);

  const toggleExpand = () => {
    setIsExpanded((prev) => !prev);
  };

  useEffect(() => {
    if (contentRef.current) {
      setIsOverflowing(contentRef.current.clientHeight > MAX_HEIGHT_PX);
    }
  }, [content]);

  return (
    <div className="relative">
      <div
        className={`relative mt-0.5 rounded text-sm text-amber-900 ${
          isExpanded ? '' : 'max-h-16 overflow-hidden'
        } ${
          !isExpanded && isOverflowing
            ? '[mask-image:linear-gradient(to_bottom,rgba(0,0,0,1),rgba(0,0,0,0))]'
            : ''
        }`}
        style={{ paddingBottom: isOverflowing && !isExpanded ? '2rem' : '0' }}
      >
        <PolicyMarkdown
          ref={contentRef}
          content={{
            type: PolicyContentType.Markdown,
            content: content,
          }}
        />
      </div>
      {isOverflowing && (
        <div className="">
          <button
            onClick={toggleExpand}
            type="button"
            className="pt-2 text-sm font-semibold text-teal-800 hover:text-teal-900"
          >
            {isExpanded ? 'Show less' : 'Read more'}
          </button>
        </div>
      )}
    </div>
  );
};
