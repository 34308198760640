import { clsx } from 'clsx';
import { FunctionComponent } from 'react';
import { Link, useMatch, useSearchParams } from 'react-router-dom';
import { PageHeader } from '@mosey/components/page-header/PageHeader';
import { PageTitle } from '@mosey/components/page-header/PageTitle';
import { Button } from '@mosey/components/buttons/Button';
import { LocationIconFilled } from '@mosey/components/Icons';
import { DropdownMenu, MenuItem } from '@mosey/components/menus/DropdownMenu';
import { Inbox } from '../components';
import { Mail, User, LegalEntityRegion, RegionStatus } from '../types';
import { APP_BASE_URL } from '../settings/config';
import { useLegalEntityRegions } from '../hooks/useUser';
import { DateRangePicker } from '../components/dates/DateRangePicker';

export type MailRoomProps = {
  isIncorporationState: boolean;
  isForeignQualified: boolean;
  mailId?: string;
  messages: Mail[];
  users: User[];
  toggleReloadView: () => void;
  legalEntityRegion?: LegalEntityRegion;
};

export const MailRoom: FunctionComponent<MailRoomProps> = ({
  isIncorporationState,
  isForeignQualified,
  mailId,
  messages,
  users,
  toggleReloadView,
  legalEntityRegion,
}) => {
  const isGlobal = useMatch('/mail');
  const regions = useLegalEntityRegions();
  const [searchParams, setSearchParams] = useSearchParams();
  const currentRegionCode = searchParams.get('region')?.toLowerCase();
  const currentLegalEntityRegion = regions.find(
    ({ region }) => region.code.toLowerCase() === currentRegionCode,
  );

  const shouldShowRegisteredAgentBanner =
    legalEntityRegion &&
    !legalEntityRegion.registered_agent &&
    legalEntityRegion.status !== RegionStatus.Entering &&
    (isIncorporationState || isForeignQualified);

  return (
    <div className="flex h-full flex-col bg-white">
      {isGlobal && (
        <PageHeader>
          <PageTitle>Inbox</PageTitle>

          <DropdownMenu
            buttonText={
              currentLegalEntityRegion?.region.name ?? 'All Locations'
            }
            ariaButtonText="Change mail location filter:"
            ButtonLeftIcon={LocationIconFilled}
          >
            <MenuItem
              key="All Locations"
              as="button"
              aria-label="Filter mail to All Locations"
              LeftIcon={LocationIconFilled}
              aria-pressed={!currentRegionCode}
              selected={!currentRegionCode}
              onClick={() => {
                searchParams.delete('region');
                setSearchParams(searchParams);
              }}
            >
              All Locations
            </MenuItem>
            {regions.map(({ region }) => (
              <MenuItem
                key={region.name}
                as="button"
                aria-label={`Filter mail to ${region.name}`}
                LeftIcon={LocationIconFilled}
                aria-pressed={region.code.toLowerCase() === currentRegionCode}
                selected={region.code.toLowerCase() === currentRegionCode}
                onClick={() => {
                  const targetRegionCode = region.code.toLowerCase();
                  const mail = messages.find(({ id }) => id === mailId);

                  searchParams.set('region', targetRegionCode);

                  if (mail?.region_code !== targetRegionCode) {
                    searchParams.delete('mail_id');
                  }

                  setSearchParams(searchParams);
                }}
              >
                {region.name}
              </MenuItem>
            ))}
          </DropdownMenu>
          <DateRangePicker
            defaultStartDate={searchParams.get('start_date')}
            defaultEndDate={searchParams.get('end_date')}
            ariaButtonTextPrefix="Change mail date filter:"
            defaultButtonText="All Time"
            onRangeChange={(newStartDate, newEndDate) => {
              if (newStartDate) {
                searchParams.set('start_date', newStartDate);
              } else {
                searchParams.delete('start_date');
              }

              if (newEndDate) {
                searchParams.set('end_date', newEndDate);
              } else {
                searchParams.delete('end_date');
              }

              setSearchParams(searchParams);
            }}
          />
        </PageHeader>
      )}

      {shouldShowRegisteredAgentBanner && (
        <div
          className={clsx(
            'flex items-center bg-teal-700 py-3 text-white',
            isGlobal && 'px-8',
            /**
             * TODO: Update this once Tasks Overview is applied to location
             * detail pages and <PageHeader /> is used there.
             */
            !isGlobal && 'pl-7 pr-6',
          )}
        >
          <div className="flex-1">
            <h2 className="font-medium">Registered Agent Transfer</h2>
            <p className="text-sm">
              You can transfer your existing registered agent to Mosey and read
              your mail here.
            </p>
          </div>

          <Button
            as={Link}
            to={`/automation/registered-agent-transfer/${legalEntityRegion.region.id}?callback_url=${APP_BASE_URL}/locations/usa/${legalEntityRegion.region.code}/mail`}
          >
            Start transfer
          </Button>
        </div>
      )}

      <Inbox
        messages={messages}
        users={users}
        toggleReloadView={toggleReloadView}
        mailId={mailId}
      />
    </div>
  );
};
