import { Fragment } from 'react/jsx-runtime';

const RIPPLING_FIELDS = [
  {
    category: 'Employee - Employee personal information',
    fields: [
      'Legal first name',
      'Legal last name',
      'Home address - Street address',
      'Home address - City',
      'Home address - State code',
      'Home address - Zip',
      'Home address - Country',
    ],
  },
  {
    category: 'Employee - Employee information',
    fields: [
      'Work location - Street address',
      'Work location - City',
      'Work location - State code',
      'Work location - Zip',
      'Work location - Country',
      'Full-time',
      'Hourly',
      'Is remote',
      'Part-time',
      'Salaried',
      'Temporary',
      'Role ID',
      'Employment type name',
      <div className="flex flex-col" key="work-email">
        <span>Work Email</span>
        <span className="text-xs">
          Only required if you are using employee handbook
        </span>
      </div>,
    ],
  },
  {
    category: 'Employee - Employee status',
    fields: ['Start date', 'Last day of work / End date', 'Employment status'],
  },
];

export const RipplingFields = () => {
  return (
    <div className="mt-4 flow-root">
      <div className="overflow-x-auto">
        <div className="inline-block min-w-full px-1 py-2 align-middle">
          <div className="overflow-hidden rounded-lg shadow ring-1 ring-black/5">
            <table className="min-w-full divide-y divide-gray-300">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900"
                  >
                    Category
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Field
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {RIPPLING_FIELDS.map(({ category, fields }) => (
                  <Fragment key={category}>
                    <tr key={category}>
                      <th
                        rowSpan={fields.length}
                        scope="rowgroup"
                        className="py-4 pl-4 pr-3 align-top text-sm font-semibold text-gray-900"
                      >
                        {category}
                      </th>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {fields[0]}
                      </td>
                    </tr>

                    {fields.slice(1).map((field, index) => (
                      <tr key={index}>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {field}
                        </td>
                      </tr>
                    ))}
                  </Fragment>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
