import { PolicyMainContent, PolicyContentType } from './types';
import { PolicyMarkdown } from './PolicyMarkdown';

type PolicyContentRendererProps = {
  content: PolicyMainContent;
};

const Content = ({ content }: PolicyContentRendererProps) => {
  switch (content.type) {
    case PolicyContentType.Markdown:
      return <PolicyMarkdown content={content} />;
    // Not supported by backend yet
    // case PolicyContentType.MultiColumnList:
    //   return <PolicyMultiColumnList content={content} />;
    default:
      return null;
  }
};

export const PolicyContentRenderer = ({
  content,
}: PolicyContentRendererProps) => {
  return <Content content={content} />;
};
