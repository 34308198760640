import { Requirement, RequirementOverview } from '../types';
import {
  isDeferred,
  isDone,
  isInProgress,
  isNew,
  isOverdue,
  isTodo,
  isUpcoming,
} from './requirement';

export const requirementPercentComplete = (req: Requirement): number => {
  const completedRemediations = req.remediations.filter(
    (rem) => rem.is_done,
  ).length;
  return (
    Math.floor((completedRemediations / req.remediations.length) * 100) || 0
  );
};

export type RequirementCounts = {
  todoCount: number;
  inProgressCount: number;
  overdueCount: number;
  doneCount: number;
  deferredCount: number;
  lockedCount: number;
  allCount: number;
  upcomingCount: number;
  newCount: number;
};

export const computedRequirementCounts = (
  requirements: RequirementOverview[],
): RequirementCounts => {
  let todoCount = 0;
  let inProgressCount = 0;
  let overdueCount = 0;
  let doneCount = 0;
  let deferredCount = 0;
  let lockedCount = 0;
  let upcomingCount = 0;
  let allCount = 0;
  let newCount = 0;

  requirements.forEach((req) => {
    if (isTodo(req)) {
      todoCount += 1;
    }
    if (isUpcoming(req)) {
      upcomingCount += 1;
    }
    if (isOverdue(req)) {
      overdueCount += 1;
    }
    if (isDone(req)) {
      doneCount += 1;
    }
    if (req.is_blocked) {
      lockedCount += 1;
    }
    if (isInProgress(req)) {
      inProgressCount += 1;
    }
    if (isDeferred(req)) {
      deferredCount += 1;
    }
    if (isNew(req)) {
      newCount += 1;
    }
    allCount += 1;
  });

  const counts = {
    todoCount,
    inProgressCount,
    overdueCount,
    doneCount,
    deferredCount,
    lockedCount,
    allCount,
    upcomingCount,
    newCount,
  };
  return counts;
};
