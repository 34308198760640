import { clsx } from 'clsx';
import { NextButton } from './NextButton';
import { TextLink } from '@mosey/components/navigation/TextLink';
import { useResolverLocation } from '../utils/hooks';

export interface InterstitialProps {
  children: React.ReactNode;
  percentComplete?: number;
  rightHandContent?: React.ReactNode;
  actions?: React.ReactNode;
  title: React.ReactNode;
  description?: React.ReactNode;
  fullHeight?: boolean;
}

interface FullHeightWrapperProps {
  children: React.ReactNode;
}

export const FullHeightWrapper = ({ children }: FullHeightWrapperProps) => {
  return (
    <div className="flex h-full flex-col items-center overflow-hidden bg-white px-8">
      <div className="mt-6 h-[calc(100%-48px)] w-full overflow-y-auto rounded-lg border border-teal-350 sm:overflow-hidden">
        {children}
      </div>
    </div>
  );
};

export const Interstitial = ({
  percentComplete,
  rightHandContent,
  title,
  description,
  children,
  actions,
  fullHeight = true,
}: InterstitialProps) => {
  const location = useResolverLocation();

  return (
    <div
      className={clsx(
        'relative gap-x-10 bg-teal-100 sm:flex',
        fullHeight && 'h-full',
      )}
    >
      {percentComplete !== undefined && (
        <div
          role="progressbar"
          className="absolute z-10 h-4 w-full"
          aria-label={`${location ? `${location.name} setup` : 'Setup'} progress`}
          aria-valuenow={percentComplete}
          aria-valuetext={`${percentComplete}% complete`}
        >
          <svg height="4" className="w-full fill-teal-600">
            <rect height="4" width={`${percentComplete}%`} />
          </svg>
        </div>
      )}

      {rightHandContent || (
        <div
          className="absolute w-[168px] shrink-0 grow-0 self-stretch sm:relative"
          aria-hidden
        >
          <svg
            width="168"
            height="100%"
            className="overflow-visible fill-teal-800 stroke-teal-800"
            opacity="0.2"
          >
            <path
              d="M 85 0 C 90 10, 104 50, 104 100 L 104 9999999"
              fill="none"
            />
            <path
              d="M 32 0 L 32 100 C 32 285, 136 275, 136 440 L 136 9999999"
              fill="none"
            />
            <circle
              cx="104"
              cy={`${percentComplete || 33}%`}
              r="6"
              strokeWidth="0"
            />
          </svg>
        </div>
      )}

      <div className="isolate flex grow flex-col gap-y-10 overflow-y-auto p-8 sm:px-0 sm:py-20">
        <div className="space-y-2">
          <h1 className="text-4xl font-bold tracking-tight">{title}</h1>
          {description && <p className="max-w-xl">{description}</p>}
        </div>

        {children}

        <div className="flex items-center gap-x-6">
          {actions || (
            <>
              <NextButton />
              <TextLink to="/home" variant="secondary" skipInk={false}>
                Cancel
              </TextLink>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
