import { Link, LinkProps } from 'react-router-dom';
import { clsx } from 'clsx';
import { PlusCircleIcon } from '@heroicons/react/outline';

interface PlaceholderLinkProps extends Omit<LinkProps, 'className'> {
  size?: 'base' | 'small';
  fitToHeight?: boolean;
}

export const PlaceholderLink = ({
  size = 'base',
  fitToHeight,
  children,
  ...props
}: PlaceholderLinkProps) => {
  return (
    <Link
      {...props}
      className={clsx(
        'group flex items-center justify-center gap-x-3 whitespace-nowrap rounded-md border border-dashed border-sage-500 bg-sage-100 px-4 text-gray-700 transition-colors hover:border-sage-600 hover:bg-sage-200 hover:text-black focus-visible:outline-2 focus-visible:outline-rose-700',
        size === 'base' && 'font-bold',
        size === 'small' && 'text-sm font-semibold',
        fitToHeight && 'h-full',
        !fitToHeight && 'py-4',
      )}
    >
      {children}
      <PlusCircleIcon
        className={clsx(
          'shrink-0 text-sage-600 transition-colors group-hover:text-sage-700',
          size === 'base' && 'size-6',
          size === 'small' && 'size-5',
        )}
      />
    </Link>
  );
};
