import { FunctionComponent, useState } from 'react';
import { useNavigate } from 'react-router';
import {
  CheckIcon,
  ClipboardCheckIcon,
  LockClosedFilledIcon,
  RefreshIcon,
  RobotIcon,
  SortAscIcon,
  SortDescIcon,
  StopwatchIcon,
  EditIcon,
  GovernmentIcon,
  LockIcon,
} from '@mosey/components/Icons';
import { Pill } from '@mosey/components/badges/Pill';
import { RequirementComputedStatus, RequirementOverview } from '../types';
import { CategoryTag, Tooltip } from '../components';
import {
  dueDateOccurrenceDescriptor,
  formatDateFromString,
  nameFromUser,
  requirementComputedStatus,
} from '../utils/format';
import { genRequirementPath } from '../utils/paths';
import {
  CategoryToIconMapping,
  CategoryToStringMapping,
} from '../utils/category';
import { statusColorMapping } from '../utils/requirement';
import { ColumnType, SortDir, SortTableBy, sortTable } from '../utils/sort';
import { Table } from '@mosey/components/table/Table';
import { HeaderRow } from '@mosey/components/table/HeaderRow';
import { HeaderCell } from '@mosey/components/table/HeaderCell';
import { TableRow } from '@mosey/components/table/TableRow';
import { TableCell } from '@mosey/components/table/TableCell';
import { EmptyStateCell } from '@mosey/components/table/EmptyStateCell';

export const statusIconMapping: Record<
  RequirementComputedStatus,
  FunctionComponent
> = {
  [RequirementComputedStatus.Todo]: ClipboardCheckIcon,
  [RequirementComputedStatus.Done]: CheckIcon,
  [RequirementComputedStatus.Deferred]: StopwatchIcon,
  [RequirementComputedStatus.Locked]: LockClosedFilledIcon,
  [RequirementComputedStatus.Overdue]: StopwatchIcon,
  [RequirementComputedStatus.InProgress]: RefreshIcon,
  [RequirementComputedStatus.Managed]: RobotIcon,
};

type RequirementsTableRowProps = {
  requirement: RequirementOverview;
};

const RequirementsTableRow: FunctionComponent<RequirementsTableRowProps> = ({
  requirement,
}) => {
  const navigate = useNavigate();
  const status = requirementComputedStatus(requirement);
  const isLocked = status === RequirementComputedStatus.Locked;
  const statusPill = (
    <Pill
      Icon={statusIconMapping[requirementComputedStatus(requirement)]}
      variant={statusColorMapping[status]}
      size="small"
    >
      {status}
    </Pill>
  );

  return (
    <TableRow onClick={() => navigate(genRequirementPath(requirement))}>
      <TableCell>
        <div className="flex">
          <div className="flex items-start">
            {isLocked && (
              <span className="mr-1 mt-1 size-4 text-gray-400">
                <LockIcon />
              </span>
            )}
            <p className="flex-1 font-semibold">{requirement.title}</p>
          </div>
        </div>
        {requirement.requirement_context && (
          <div className="flex items-start px-6 text-xs text-gray-400">
            {requirement.requirement_context.message}
          </div>
        )}
      </TableCell>
      <TableCell>
        <div className="flex">
          {requirement.assigned_user ? (
            <p>{nameFromUser(requirement.assigned_user)}</p>
          ) : (
            <p className="italic text-gray-500">Unassigned</p>
          )}
        </div>
      </TableCell>
      <TableCell>
        <div className="flex flex-row items-center">
          <CategoryTag className="mr-2" text="State" icon={GovernmentIcon} />
          <CategoryTag
            className="mr-2"
            text={CategoryToStringMapping[requirement.category]}
            icon={CategoryToIconMapping[requirement.category]}
          />
        </div>
      </TableCell>
      <TableCell>
        <div>
          <p className="font-semibold">
            {requirement.due_date && formatDateFromString(requirement.due_date)}
          </p>
          <p className="text-xs text-gray-400">
            {dueDateOccurrenceDescriptor(requirement.schedule).toUpperCase()}
          </p>
        </div>
      </TableCell>
      <TableCell>
        {requirement.managed_provider ? (
          <Tooltip
            name={`requirement-managed-by-tooltip-${requirement.id}`}
            label={`This requirement is managed by ${requirement.managed_provider.name}`}
          >
            {statusPill}
          </Tooltip>
        ) : (
          statusPill
        )}
      </TableCell>
      <TableCell>
        <div className="size-6">
          <EditIcon />
        </div>
      </TableCell>
    </TableRow>
  );
};

type RequirementsTableProps = {
  requirements: RequirementOverview[];
  border?: boolean;
};

export const RequirementsTable: FunctionComponent<RequirementsTableProps> = ({
  requirements,
  border = true,
}) => {
  const [sortBy, setSortBy] = useState<SortTableBy>({
    column: ColumnType.DueDate,
    sortDir: SortDir.Asc,
  });
  const tableRequirements = sortTable(requirements, sortBy);

  const handleSortColumnClick = (col: ColumnType): void => {
    if (sortBy.column === col) {
      const newSortDir =
        sortBy.sortDir === SortDir.Asc ? SortDir.Desc : SortDir.Asc;
      setSortBy({ column: col, sortDir: newSortDir });
    } else {
      setSortBy({ column: col, sortDir: SortDir.Asc });
    }
  };

  const getSortIcon = () => {
    const iconClass = 'w-4 h-4 ml-2';
    if (sortBy.sortDir === SortDir.Asc) {
      return <SortAscIcon className={iconClass} />;
    }
    return <SortDescIcon className={iconClass} />;
  };

  const getTableHeader = (
    name: string,
    columnType: ColumnType,
    width: string,
  ) => {
    return (
      <HeaderCell
        onClick={() => handleSortColumnClick(columnType)}
        width={width}
      >
        <div className="flex items-center">
          {name}
          {sortBy.column === columnType && getSortIcon()}
          {sortBy.column !== columnType && (
            <SortAscIcon className="ml-2 size-4 min-w-4 opacity-0 group-hover/headerCell:opacity-100" />
          )}
        </div>
      </HeaderCell>
    );
  };

  return (
    <Table border={border}>
      <HeaderRow>
        {getTableHeader('Requirement', ColumnType.ReqName, 'min-w-80')}
        {getTableHeader('Assigned to', ColumnType.AssignedTo, 'w-36')}
        {getTableHeader('Category', ColumnType.Category, 'w-36')}
        {getTableHeader('Due Date', ColumnType.DueDate, 'w-36')}
        {getTableHeader('Status', ColumnType.Status, 'w-36')}
        <HeaderCell>Edit</HeaderCell>
      </HeaderRow>
      <tbody>
        {tableRequirements.length > 0 ? (
          tableRequirements.map((r: RequirementOverview) => (
            <RequirementsTableRow
              key={`${r.id}-${r.data_id}-${r.due_date}`}
              requirement={r}
            />
          ))
        ) : (
          <TableRow>
            <EmptyStateCell colSpan={6}>No requirements found</EmptyStateCell>
          </TableRow>
        )}
      </tbody>
    </Table>
  );
};
