import { PageHeader } from '@mosey/components/page-header/PageHeader';
import { Button } from '@mosey/components/buttons/Button';
import { Link, Navigate, useFetcher } from 'react-router-dom';
import { BackButton } from '../../../components';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { StandaloneRadioGroup } from '../../location_detail_setup_new/StandaloneRadioGroup';
import { ArrowRightIcon } from '@heroicons/react/outline';
import { usePolicySections } from '../hooks/usePolicySections';

type FormData = {
  section: string | undefined;
};

export const AddSection = () => {
  const { unusedSections } = usePolicySections();
  const form = useForm<FormData>({
    defaultValues: { section: unusedSections[0]?.id },
  });
  const { control } = form;
  const { Form } = useFetcher();
  const section = useWatch({ name: 'section', control });

  if (unusedSections.length === 0) {
    return <Navigate to="/handbook/overview" replace />;
  }

  return (
    <>
      <PageHeader>
        <BackButton to="/handbook/overview" />
      </PageHeader>
      <FormProvider {...form}>
        <Form className="max-w-3xl space-y-8 px-8 py-6">
          <h1 className="py-2 text-xl font-semibold">
            Add a section to your handbook
          </h1>
          <div className="rounded-sm bg-sage-100 p-9">
            <h2 className="mb-3 text-lg font-bold text-gray-700">
              Which section would you like to add?
            </h2>
            <StandaloneRadioGroup
              name="section"
              options={unusedSections.map((section) => ({
                label: section.title,
                value: section.id,
              }))}
              reactFormConfig={{
                required: 'Section is required',
              }}
            />
            <div className="mt-6">
              <Button
                as={Link}
                to={`/handbook/section/${section}/policies/create`}
                rightIcon={<ArrowRightIcon className="size-5 text-white" />}
              >
                Next
              </Button>
            </div>
          </div>
        </Form>
      </FormProvider>
    </>
  );
};
