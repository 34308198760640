import { useMemo } from 'react';
import { useNavigation } from 'react-router-dom';
import { TaskSummaryCard } from '@mosey/components/layout/Card';
import { usePendingSearchParamsValue } from '@mosey/components/hooks/navigation';
import { TaskRef } from '../utils/types';
import { useTasksOverviewData } from '../utils/hooks';
import {
  getTaskDueDate,
  isTaskAutomated,
  isTaskDone,
  isTaskManaged,
  isTaskOverdue,
  isTaskTodo,
} from '../utils';
import { TasksOverviewZeroState } from './TasksOverviewZeroState';
import { TasksOverviewStatusFilter } from './TasksOverviewStatusFilter';
import { Loading } from '../../Loading';
import { TaskCardStatus } from '@mosey/components/layout/types';

export const TasksOverviewMainTasks = () => {
  const navigation = useNavigation();
  const status = usePendingSearchParamsValue('status');
  const { tasks } = useTasksOverviewData();

  const filteredTasks = useMemo(() => {
    const result: TaskRef[] = [];

    for (let i = 0; i < tasks.length; i++) {
      const task = tasks[i];

      if (task.source.type === 'requirement') {
        const isTodo = isTaskTodo(task);
        const isDone = isTaskDone(task);
        const isAutomated = isTaskAutomated(task);
        const isManaged = isTaskManaged(task);
        const isOverdue = isTaskOverdue(task);

        switch (status) {
          case 'done':
            if (isDone && !isAutomated && !isManaged) {
              result.push(task);
            }

            break;

          case 'overdue':
            if (isOverdue) {
              result.push(task);
            }

            break;

          case 'automated':
            if (isAutomated && !isManaged) {
              result.push(task);
            }

            break;

          case 'managed':
            if (isManaged && !isAutomated) {
              result.push(task);
            }

            break;

          case 'todo':
          case null:
          default:
            if (isTodo && !isManaged) {
              result.push(task);
            }

            break;
        }
      }
    }

    return result.sort((a, b) => {
      if (a.source.type === 'requirement' && b.source.type === 'requirement') {
        const aDueDate = a.source.due_date;
        const bDueDate = b.source.due_date;

        if (!aDueDate && !bDueDate) {
          return 0;
        } else if (!aDueDate && bDueDate) {
          return 1;
        } else if (aDueDate && !bDueDate) {
          return -1;
        } else if (aDueDate && bDueDate) {
          const aDate = new Date(aDueDate);
          const bDate = new Date(bDueDate);

          if (aDate < bDate) {
            return -1;
          } else if (aDate > bDate) {
            return 1;
          }
        }

        return 0;
      }

      return 0;
    });
  }, [tasks, status]);

  return (
    <section className="@5xl/tasks-overview:h-full">
      <TasksOverviewStatusFilter />

      <div aria-live="polite" className="flex h-full flex-col">
        {navigation.state === 'loading' &&
        navigation.location.pathname === location.pathname ? (
          /**
           * This ensures the spinner is centered in the visible area below the
           * filters. The height is calculated as follows:
           *
           * 100vh - header height - reguion filter height - status filter height
           */
          <div className="max-h-[calc(100vh-80px-90px-40px)] grow">
            <Loading />
          </div>
        ) : filteredTasks.length > 0 ? (
          <ul className="space-y-2">
            {filteredTasks.map((task) => {
              const { id, status, title, source } = task;
              const formattedDueDate = getTaskDueDate(task);
              let calculatedStatus: TaskCardStatus =
                status === 'deferred' ? 'todo' : status;

              if (isTaskManaged(task)) {
                calculatedStatus = 'managed';
              } else if (isTaskOverdue(task)) {
                calculatedStatus = 'overdue';
              } else if (isTaskAutomated(task)) {
                calculatedStatus = 'automated';
              }

              return (
                <li key={id}>
                  <TaskSummaryCard
                    status={calculatedStatus}
                    title={title}
                    size="medium"
                    description={
                      source.type === 'requirement'
                        ? source.summary || source.description
                        : undefined
                    }
                    reason={
                      formattedDueDate
                        ? { title: `Due ${formattedDueDate}`, type: 'due' }
                        : undefined
                    }
                    to={
                      source.type === 'question'
                        ? `/resolver/question/tasks/${id}`
                        : `/requirement/${id}`
                    }
                    region={
                      source.type === 'requirement'
                        ? source.location.name
                        : source.locations[0].name
                    }
                  />
                </li>
              );
            })}
          </ul>
        ) : (
          <TasksOverviewZeroState />
        )}
      </div>
    </section>
  );
};
