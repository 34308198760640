import { TextLink } from './TextLink';

export const LegalLinks = () => {
  return (
    <div className="mt-4 text-sm text-gray-500">
      By continuing, you are agreeing to the Mosey{' '}
      <TextLink to="https://mosey.com/terms-of-service" target="_blank">
        terms of service
      </TextLink>{' '}
      and{' '}
      <TextLink to="https://mosey.com/privacy-policy" target="_blank">
        privacy policy
      </TextLink>
      .
    </div>
  );
};
