type PageHeaderProps = {
  children: React.ReactNode;
};

export const PageHeader = ({ children }: PageHeaderProps) => {
  return (
    <header className="sticky top-0 z-[900] flex h-20 shrink-0 items-center gap-x-6 border-b bg-white px-8">
      {children}
    </header>
  );
};
