import { ExpandablePolicyMarkdown } from '@mosey/components/handbook/ExpandablePolicyMarkdown';
import { Filter, FilterOption } from '@mosey/components/menus/Filter';
import { useState, useMemo } from 'react';
import { getPolicyLocationLabel } from '../utils';
import { DetailedPolicy } from '../types';
import { PolicyDetailsContainer } from '@mosey/components/handbook/PolicyDetailsContainer';
import { usePolicySectionAttributeValues } from '../hooks/usePolicySectionAttributeValues';
import { fillContentItem } from '@mosey/utils/templates/render';

export type PolicyCategoryFullDetailsProps = {
  policies: DetailedPolicy[];
};

export const PolicyCategoryFullDetails = ({
  policies,
}: PolicyCategoryFullDetailsProps) => {
  const attributeValues = usePolicySectionAttributeValues();

  const filterOptions = useMemo(
    () =>
      policies.map((policy) => ({
        label: getPolicyLocationLabel(policy),
        value: policy.id,
      })),
    [policies],
  );
  const [selectedOption, setSelectedOption] = useState<FilterOption>(
    filterOptions[0],
  );
  const selectedPolicy = policies.find(
    (policy) => policy.id === selectedOption.value,
  );

  const details = useMemo(
    () =>
      fillContentItem(
        selectedPolicy?.content.full_details || '',
        attributeValues,
        selectedPolicy?.policy_scope?.region_id,
      ),
    [
      selectedPolicy?.content.full_details,
      attributeValues,
      selectedPolicy?.policy_scope?.region_id,
    ],
  );

  if (filterOptions.length === 0) {
    return null;
  }

  return (
    <PolicyDetailsContainer
      header={
        filterOptions.length > 1 ? (
          <>
            <div>Full policy details for</div>
            <Filter
              options={filterOptions}
              selectedOption={selectedOption || filterOptions[0]}
              setSelectedOption={setSelectedOption}
            />
          </>
        ) : (
          <div className="py-2">
            {`Full policy details for ${filterOptions[0].label}`}
          </div>
        )
      }
      content={<ExpandablePolicyMarkdown content={details} />}
    />
  );
};
