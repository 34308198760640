import {
  Requirement,
  RequirementComputedStatus,
  RequirementOverview,
  RequirementStatus,
} from '../types';
import {
  convertDateStringToLocalDate,
  convertDateToUTC,
  getLocalTodayMidnight,
} from '@mosey/utils/dates';
import { PillProps } from '@mosey/components/badges/Pill';
import { isHandbookRequirement } from '../views/handbook/utils';

export const isUpcoming = (requirement: RequirementOverview): boolean => {
  const today = getLocalTodayMidnight();

  // Construct a date 90 days from today
  const todayPlusNinety = getLocalTodayMidnight();
  todayPlusNinety.setDate(todayPlusNinety.getDate() + 90);

  if (requirement.due_date) {
    if (requirement.is_virtual || requirement.is_blocked) {
      return false;
    }
    const dueDate = convertDateStringToLocalDate(requirement.due_date);

    return dueDate >= today && dueDate <= todayPlusNinety;
  }
  return false;
};

export const isAttributeProducer = (requirement: Requirement) => {
  return requirement.produces.length > 0;
};

export const isManagedByMosey = (requirement: RequirementOverview): boolean => {
  if (!requirement.is_managed) {
    return false;
  }

  if (requirement.managed_provider?.name === 'Mosey') {
    return true;
  }

  // For compatibility, any requirement that is managed but has a null
  // provider is actually managed by Mosey. This won't be needed once
  // the state setup flow marks requirements as managed _and_ sets the
  // provider correctly.
  if (!requirement.managed_provider) {
    return true;
  }

  return false;
};

export const isOverdue = (requirement: RequirementOverview): boolean => {
  if (requirement.is_managed || requirement.is_blocked) {
    return false;
  }

  if (requirement.due_date && requirement.status !== RequirementStatus.Done) {
    // Compare local dates because we want to see if the requirement is past due for them
    const localToday = getLocalTodayMidnight();
    const localDueDate = convertDateStringToLocalDate(requirement.due_date);

    // the task is overdue if the due date is in the past
    return localDueDate < localToday;
  }
  return false;
};

export const isDone = (requirement: RequirementOverview): boolean => {
  // Only show a managed recurring requirement as 'done' if it's
  // within two weeks of the due date or the due_date has passed.
  if (requirement.is_managed && requirement.due_date) {
    let today = new Date();
    today = convertDateToUTC(today);

    const dueDate = new Date(requirement.due_date);

    if (dueDate <= today) {
      return true;
    }

    // Construct a UTC date 14 days from today
    let todayPlusFourteen = new Date();
    todayPlusFourteen.setDate(todayPlusFourteen.getDate() + 14);
    todayPlusFourteen = convertDateToUTC(todayPlusFourteen);

    return dueDate >= today && dueDate <= todayPlusFourteen;
  }

  // A one time managed requirement is always considered done unless
  // it's managed by Mosey (in which case we have more data about the status)
  if (requirement.is_managed && requirement.schedule.category === 'one-time') {
    if (isManagedByMosey(requirement)) {
      return requirement.status === RequirementStatus.Done;
    }
    return true;
  }

  return requirement.status === RequirementStatus.Done;
};

export const isSkipped = (requirement: RequirementOverview): boolean => {
  return requirement.status === RequirementStatus.Skipped;
};

export const isTax = (requirement: RequirementOverview): boolean => {
  return requirement.category === 'tax' || requirement.category === 'tax-setup';
};

export const isPayroll = (requirement: RequirementOverview): boolean => {
  return (
    requirement.category === 'payroll' ||
    requirement.category === 'payroll-setup'
  );
};

export const isRegistration = (requirement: RequirementOverview): boolean => {
  return (
    requirement.category === 'registration' ||
    requirement.category === 'registration-maintenance'
  );
};

export const isRegistrationMaintenance = (
  requirement: RequirementOverview,
): boolean => {
  return requirement.category === 'registration-maintenance';
};

export const isHR = (requirement: RequirementOverview): boolean => {
  return requirement.category === 'hr';
};

export const isInsurance = (requirement: RequirementOverview): boolean => {
  return (
    requirement.category === 'insurance' ||
    requirement.category === 'insurance-setup'
  );
};

export const isTodo = (requirement: RequirementOverview): boolean => {
  return requirement.status == RequirementStatus.Todo;
};

export const isInProgress = (requirement: RequirementOverview): boolean => {
  return requirement.status == RequirementStatus.InProgress;
};

export const isDeferred = (requirement: RequirementOverview): boolean => {
  return requirement.status === RequirementStatus.Deferred;
};

export const isNew = (requirement: RequirementOverview): boolean => {
  // Construct a UTC date 5 days from today
  let todayMinusFive = new Date();
  todayMinusFive.setDate(todayMinusFive.getDate() - 5);
  todayMinusFive = convertDateToUTC(todayMinusFive);
  return new Date(requirement.created_at) >= todayMinusFive;
};

export const isAssigned = (requirement: Requirement): boolean => {
  return !!requirement.assigned_user;
};

export const isOneTimeRequirement = (requirement: Requirement): boolean => {
  return requirement.schedule.category === 'one-time';
};

export const isSetupRequirementCategory = (
  requirement: Requirement,
): boolean => {
  return (
    requirement.category === 'registration' ||
    requirement.category.includes('-setup')
  );
};

export const isAll = (): boolean => {
  return true;
};

export const statusColorMapping: {
  [key in RequirementComputedStatus]: PillProps['variant'];
} = {
  [RequirementComputedStatus.Todo]: 'secondary',
  [RequirementComputedStatus.Done]: 'success',
  [RequirementComputedStatus.Deferred]: 'inactive',
  [RequirementComputedStatus.Locked]: 'inactive',
  [RequirementComputedStatus.Overdue]: 'primary',
  [RequirementComputedStatus.InProgress]: 'automation',
  [RequirementComputedStatus.Managed]: 'automation',
};

/**
 * Determines if the requirement is the next one that needs attention during
 * state setup.
 */
export const isSetupRequirementNextable = (
  requirement: Requirement,
): boolean => {
  return (
    isOneTimeRequirement(requirement) &&
    !isAssigned(requirement) &&
    !requirement.is_blocked &&
    !isSkipped(requirement) &&
    !isDeferred(requirement) &&
    !isDone(requirement)
  );
};

export const ManagedPayrollReqsByState: Record<string, string[]> = {
  al: ['5e40808e', '62b9d11b'],
  ak: ['e48717c5'],
  az: ['ee40f009', 'c7b2c51f'],
  ar: ['345c60f8', '8198aa2e'],
  ca: ['ffcf700d'],
  ct: ['e81a9bc8', 'd8af67d2', '1f56bf2d'],
  de: ['c06b95f7', '743505dd'],
  co: ['5f2efd18', '3f15ccab', 'd7b55a6f'],
  dc: ['b0c604ee', '771c137f', 'b38ca80f'],
  fl: ['a6ca5d56'],
  ga: ['9f1897c8', 'f950b60a'],
  hi: ['750ecd24', 'd2fc06b1'],
  id: ['20360481', '1569481a'],
  il: ['0f30b82a', '2c1881dd'],
  in: ['aed70104', 'd2bc6a52'],
  ia: ['8463275e', 'f7bdcd43'],
  ks: ['9275a8db', 'c6ad5e0f'],
  ky: ['5735dc57', '0e32857c'],
  la: ['8d703088', '7ea8a0cc'],
  ma: ['dba9b4ba', '71350337', '06995bd7'],
  md: ['7d1edcf5', '81b07a98'],
  me: ['93fedb78', '83af7e81'],
  mi: ['282e730b', '3958e5a0'],
  mn: ['c5c03314', 'debbc058'],
  mo: ['b0c27790', '49d1ce6c'],
  ms: ['28f9d710', '80c25004'],
  ne: ['c8e60eae', '80a1d5c9'],
  nc: ['bf9b4638', 'b769af08'],
  nd: ['048a4766', '6090d9f9', 'e2b86d8e'],
  nj: ['8edbc60d', '949672da'],
  nm: ['1827f2e0', '6970bebd'],
  nv: ['269fe702', '7b57eb19'],
  nh: ['388ea3fb'],
  ny: ['53adab6a', '8c6421f1'],
  oh: ['013945c9', '3a05ba55', 'd6e49eb0'],
  ok: ['4e7ba9e7', '004cc99f'],
  or: ['0db9ec74', '2d006e72'],
  pa: ['1e98e265', '99bff5f2'],
  mt: ['e752ebeb', '0f81eea5'],
  ri: ['af89d508', '07be759f'],
  sc: ['bba7a741', '386a5a80'],
  sd: ['bf1894ee'],
  tn: ['462455f2'],
  tx: ['bc070b35'],
  ut: ['9ac8def5', '6895a958'],
  vt: ['078662ed', '50e7933b'],
  va: ['1621e77b', 'c35bb4de'],
  wa: ['a47171e5', '6a8f39c4', '91733fbc'],
  wv: ['ab736e83', 'e2c76e97'],
  wi: ['28d8583e', '534d6fd6'],
  wy: ['9580f877', 'e5f7ec3c', '77de70ba'],
};

export function shouldRequirementSkipCompletionScreen(
  requirement: Requirement,
) {
  return isHandbookRequirement(requirement);
}
