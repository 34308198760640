import { FunctionComponent, useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { LeftIcon, RightIcon } from '@mosey/components/Icons';
import {
  threeMonthPeriod,
  nextMonth,
  prevMonth,
  lastDayOfMonth,
  firstDayOfMonth,
  convertDateToUTC,
  toUTCMidnight,
} from '@mosey/utils/dates';
import { PageHeader } from '@mosey/components/page-header/PageHeader';
import { PageTitle } from '@mosey/components/page-header/PageTitle';
import {
  MonthCalendar,
  ApiStatusHandler,
  RequirementsTable,
  QuickFilters,
} from '../components';
import {
  FilterRequirementsBy,
  filterRequirements,
  getCountForFilter,
} from '../utils/filter';
import { computedRequirementCounts } from '../utils/stats';
import { formatDateFromString, isoDate } from '../utils/format';
import { fetchApi } from '../utils/fetchApi';
import { ApiStatus, IApiData } from '../utils/types';
import { RequirementOverview } from '../types';
import { Loading } from './Loading';

type ComplianceCalendarProps = {
  data: RequirementOverview[];
  startDate: string;
  endDate: string;
  isLoading: boolean;
};

const inDateRange = (startDate: Date, endDate: Date, d: Date): boolean =>
  d >= startDate && d <= endDate;

export const ComplianceCalendar: FunctionComponent<ComplianceCalendarProps> = ({
  data,
  startDate,
  endDate,
  isLoading,
}) => {
  const navigate = useNavigate();
  const [reqFilter, setFilter] = useState(FilterRequirementsBy.All);

  const start = toUTCMidnight(new Date(startDate));
  const end = toUTCMidnight(new Date(endDate));

  // Gather all requirement that have a due date and are not blocked
  const reqs = data.filter(
    (r) =>
      r.due_date &&
      inDateRange(start, end, new Date(r.due_date)) &&
      !r.is_blocked,
  );
  const computedReqCounts = computedRequirementCounts(reqs);
  const filteredReqs = filterRequirements(reqs, reqFilter);

  const handlePrevMonthClick = (): void => {
    const prevStartDate = isoDate(firstDayOfMonth(prevMonth(start)));
    const prevEndDate = isoDate(
      lastDayOfMonth(prevMonth(firstDayOfMonth(end))),
    );
    navigate(`/calendar?start_date=${prevStartDate}&end_date=${prevEndDate}`);
  };

  const handleNextMonthClick = () => {
    const nextStartDate = isoDate(firstDayOfMonth(nextMonth(start)));
    const nextEndDate = isoDate(
      lastDayOfMonth(nextMonth(firstDayOfMonth(end))),
    );
    navigate(`/calendar?start_date=${nextStartDate}&end_date=${nextEndDate}`);
  };

  const handleStatusFilterClick = (filter: FilterRequirementsBy) => {
    setFilter(filter);
  };

  const allowedFilters = [
    FilterRequirementsBy.All,
    FilterRequirementsBy.Upcoming,
    FilterRequirementsBy.Overdue,
    FilterRequirementsBy.Done,
  ];

  // omit year on start date when range is within the same year
  const formattedStartDate =
    start.getUTCFullYear() === end.getUTCFullYear()
      ? formatDateFromString(startDate, { month: 'long', day: 'numeric' })
      : formatDateFromString(startDate);

  return (
    <div className="flex h-full flex-col">
      <PageHeader>
        <PageTitle>
          Calendar ({formattedStartDate} – {formatDateFromString(endDate)})
        </PageTitle>

        <div className="mt-2.5">
          <QuickFilters
            items={allowedFilters.map((filter) => {
              return {
                label: filter,
                count: getCountForFilter(computedReqCounts, filter),
                isSelected: reqFilter === filter,
              };
            })}
            onClick={handleStatusFilterClick}
          />
        </div>
      </PageHeader>

      <div className="grid grow grid-cols-[minmax(0,_1fr)_348px] overflow-hidden">
        <div className="flex-1 overflow-auto border-r">
          {isLoading ? (
            <Loading />
          ) : (
            <RequirementsTable requirements={filteredReqs} border={false} />
          )}
        </div>
        <div className="overflow-auto px-6 pt-8">
          <div className="flex h-0 justify-between">
            <button
              type="button"
              className="size-6"
              onClick={handlePrevMonthClick}
              aria-label="Fetch requirements from previous month"
            >
              <LeftIcon />
            </button>
            <button
              type="button"
              className="size-6"
              onClick={handleNextMonthClick}
              aria-label="Fetch requirements from next month"
            >
              <RightIcon />
            </button>
          </div>
          <MonthCalendar startDate={start} requirements={filteredReqs} />
        </div>
      </div>
    </div>
  );
};

export const ComplianceCalendarView: FunctionComponent = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<IApiData>({
    status: ApiStatus.Loading,
    error: null,
    data: null,
  });
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  let startDate = searchParams.get('start_date');
  let endDate = searchParams.get('end_date');

  // Search params might be empty so default to a 90 day date range
  if (!startDate && !endDate) {
    const d = convertDateToUTC(new Date());
    const { startDate: start, endDate: end } = threeMonthPeriod(d);
    startDate = isoDate(start);
    endDate = isoDate(end);
  }

  const extraProps = { startDate, endDate, isLoading };

  useEffect(() => {
    setIsLoading(true);

    fetchApi({
      url: '/api/requirements/calendar',
      method: 'POST',
      body: {
        /* eslint-disable camelcase */
        start_date: startDate,
        end_date: endDate,
        /* eslint-enable camelcase */
      },
    }).then((apiData) => {
      setData(apiData);
      setIsLoading(false);
    });
  }, [startDate, endDate]);

  return (
    <ApiStatusHandler
      status={data.status}
      error={data.error}
      data={data.data}
      extraProps={extraProps}
      component={ComplianceCalendar}
    />
  );
};
