import { DetailedPolicy } from '../types';
import { useMemo } from 'react';
import { RegionScopedContent } from '@mosey/components/handbook/RegionScopedContent';
import { usePolicySectionAttributeValues } from '../hooks/usePolicySectionAttributeValues';
import { fillContentItem } from '@mosey/utils/templates/render';

type PolicyGroupMainContentProps = {
  policies: Array<DetailedPolicy>;
  regions: Array<string>;
};

export const PolicyGroupMainContent = ({
  policies,
  regions,
}: PolicyGroupMainContentProps) => {
  const attributeValues = usePolicySectionAttributeValues();
  const mainContent = useMemo(
    () =>
      policies[0].content.main.map((piece) => ({
        ...piece,
        content: fillContentItem(
          piece.content,
          attributeValues,
          policies[0].policy_scope?.region_id,
        ),
      })),
    [policies, attributeValues],
  );
  return <RegionScopedContent regions={regions} mainContent={mainContent} />;
};
