import Handlebars from 'handlebars';
import { paths, components } from '@mosey/api-types';

type AttributeValues =
  paths['/api/handbook/policy_section/{policy_section_public_id}/attribute_values']['get']['responses']['200']['content']['application/json'];
export type Policy = components['schemas']['Policy'];
export type PolicyContent = components['schemas']['PolicyContent'];
export type PolicyMarkdownContent = components['schemas']['PolicyMainContent'];

type TemplateValue = string | number | boolean | undefined | null;

const isValidNumber = (value: TemplateValue): boolean => {
  if (value === null || value === undefined) return false;
  if (typeof value === 'boolean') return false;
  return !isNaN(parseInt(value as string));
};

const HELPERS = {
  eq: (a: TemplateValue, b: TemplateValue): boolean => a === b,
  neq: (a: TemplateValue, b: TemplateValue): boolean => a !== b,
  gt: (a: TemplateValue, b: TemplateValue): boolean => {
    if (isValidNumber(a) && isValidNumber(b)) {
      return parseInt(a as string) > parseInt(b as string);
    }
    return false;
  },
  gte: (a: TemplateValue, b: TemplateValue): boolean => {
    if (isValidNumber(a) && isValidNumber(b)) {
      return parseInt(a as string) >= parseInt(b as string);
    }
    return false;
  },
  lt: (a: TemplateValue, b: TemplateValue): boolean => {
    if (isValidNumber(a) && isValidNumber(b)) {
      return parseInt(a as string) < parseInt(b as string);
    }
    return false;
  },
  lte: (a: TemplateValue, b: TemplateValue): boolean => {
    if (isValidNumber(a) && isValidNumber(b)) {
      return parseInt(a as string) <= parseInt(b as string);
    }
    return false;
  },
  and: (a: TemplateValue, b: TemplateValue): boolean => {
    return Boolean(a) && Boolean(b);
  },
  or: (a: TemplateValue, b: TemplateValue): boolean => {
    return Boolean(a) || Boolean(b);
  },
};

export function fillContentItem(
  item: string,
  attributeValues: AttributeValues,
  regionId?: number | null,
) {
  const globals = attributeValues[-1] || {};
  const relevantAttributes =
    regionId && attributeValues[regionId]
      ? { ...globals, ...attributeValues[regionId] }
      : globals;
  return Handlebars.compile(item, { noEscape: true })(
    { attribute: relevantAttributes },
    {
      helpers: HELPERS,
    },
  );
}

export function fillPolicyContent(
  content: PolicyContent,
  attributeValues: AttributeValues,
  regionId?: number | null,
): PolicyContent {
  return {
    main: content.main.map((item) => ({
      ...item,
      content: fillContentItem(
        (item as PolicyMarkdownContent).content,
        attributeValues,
        regionId,
      ),
    })),
    full_details: content.full_details
      ? fillContentItem(content.full_details, attributeValues, regionId)
      : null,
    resources: content.resources,
  };
}
