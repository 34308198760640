import { captureMessage } from '@sentry/react';

// should be used for any user-supplied value
export function safelyUpdateLocation(path: string, replace = false) {
  try {
    const url = new URL(path);
    if (url.protocol !== 'https:' && url.origin !== window.location.origin) {
      return;
    }

    // TODO: use an allow list of valid redirection targets as well
    captureMessage(`Safely redirecting user to: ${url.origin}`, 'debug');

    if (replace) {
      window.location.replace(url);
    } else {
      window.location.href = url.toString();
    }
  } catch (err) {
    return;
  }
}
