import { ReactNode } from 'react';
import { clsx } from 'clsx';

type TableProps = {
  children: ReactNode;
  border?: boolean;
};

export const Table = ({ children, border = true }: TableProps) => {
  return (
    <div className="w-full overflow-x-auto">
      <table className={clsx('w-full', border && 'border')}>{children}</table>
    </div>
  );
};
