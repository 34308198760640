type PageTitleProps = {
  children: React.ReactNode;
};

export const PageTitle = ({ children }: PageTitleProps) => {
  return (
    <h1 className="flex-1 truncate text-2xl font-bold tracking-tight text-gray-900">
      {children}
    </h1>
  );
};
