import {
  Link,
  LoaderFunction,
  Navigate,
  useLoaderData,
  useParams,
} from 'react-router-dom';
import {
  MailOpenIcon,
  AnnotationIcon,
  ShieldCheckIcon,
} from '@heroicons/react/outline';
import { paths } from '@mosey/api-types';
import { USStateId } from '@mosey/utils/constants/us-states';
import { CheckIcon } from '@heroicons/react/solid';
import { Button } from '@mosey/components/buttons/Button';
import {
  useTodoTasks,
  useResolverUrl,
  useResolverLocation,
} from '../utils/hooks';
import successCloudImage from '../../../assets/success-cloud.svg';
import smallCloudImage from '../../../assets/small-cloud.svg';
import confetti from '../../../assets/tasks-framework/task-resolver-interstitial-confetti.svg';
import { ResolverType, ResolverRouteParams } from '../utils/types';
import { ResolverSidebar } from './Sidebar';
import { FullHeightWrapper, Interstitial } from '../common/Interstitial';
import { NextButton } from '../common/NextButton';
import { getFinishRedirectUrl } from '../utils/session';
import { api } from '../../../utils/fetchApi';
import { MutedStateSeal } from '../common/MutedStateSeal';

const stateSetupCompleteBullets = [
  {
    title: 'Weekly Summary',
    description:
      'You will receive a weekly summary email that includes any upcoming tasks',
    Icon: MailOpenIcon,
  },
  {
    title: 'Threshold Notifications',
    description:
      'You will be notified when you hit thresholds such as employee counts or payroll amounts that require further action',
    Icon: AnnotationIcon,
  },
  {
    title: '24/7 Monitoring',
    description:
      'Ongoing monitoring of upcoming laws and changes in requirements ensure that you will always be compliant',
    Icon: ShieldCheckIcon,
  },
];

export const loader: LoaderFunction = async ({
  params: { countryId, locationId, resolverType },
}) => {
  if (
    resolverType !== ResolverType.Assessment &&
    resolverType !== ResolverType.Question &&
    resolverType !== ResolverType.Review
  ) {
    return false;
  }

  const finishRedirectUrl = getFinishRedirectUrl();

  if (finishRedirectUrl) {
    /**
     * If we have a finishRedirectUrl, that means we're coming from JM, so there
     * are no new tasks since they will return to JM to finish setup.
     */
    return false;
  }

  let regionId: USStateId | undefined;
  const searchParams = new URLSearchParams();

  if (countryId && locationId) {
    regionId = USStateId[locationId.toUpperCase() as keyof typeof USStateId];

    if (regionId) {
      searchParams.set('region_id', regionId);
    }
  }

  searchParams.set(
    'is_setup',
    resolverType === ResolverType.Assessment ? 'true' : 'false',
  );
  searchParams.set('task_type', ResolverType.Requirement);

  const response = await api({
    url: `/api/compliance/tasks?${searchParams.toString()}`,
    method: 'GET',
  });
  const newRequirementTasks =
    (await response.json()) as paths['/api/compliance/tasks']['get']['responses'][200]['content']['application/json'];

  return newRequirementTasks.length > 0;
};

const GenericCompleteGraphic = () => (
  <div className="relative">
    <img className="w-96" src={successCloudImage} alt="" />

    <div className="absolute left-1/2 top-1/2 size-36 -translate-x-1/2 -translate-y-1/2 rounded-full bg-lime-500 text-white">
      <CheckIcon strokeWidth={3} aria-hidden="true" />
    </div>

    <img
      className="absolute right-20 top-24 w-16 -translate-y-2 translate-x-6"
      src={smallCloudImage}
      alt=""
    />

    <img
      className="absolute bottom-24 left-20 w-16 -translate-x-2 translate-y-4"
      src={smallCloudImage}
      alt=""
    />
  </div>
);

const StateSetupComplete = () => {
  const location = useResolverLocation()!;

  return (
    <FullHeightWrapper>
      <Interstitial
        percentComplete={100}
        title={
          <>
            You’re all set up in{' '}
            <span className="text-teal-800">{location.name}</span>!
          </>
        }
        description="You’ve activated monitoring and alerting for state and local compliance."
        rightHandContent={
          <div
            className="bg-no-repeat py-8 sm:w-[512px] sm:bg-[center_top_90px] sm:pb-0 sm:pt-[135px]"
            style={{ backgroundImage: `url(${confetti})` }}
          >
            <div className="mx-auto size-[320px]">
              <MutedStateSeal />
            </div>
          </div>
        }
        actions={<NextButton to="/home">Finish</NextButton>}
      >
        <ul className="max-w-xl space-y-10 text-zinc-700">
          {stateSetupCompleteBullets.map(({ title, description, Icon }) => (
            <li key={title} className="flex gap-x-3">
              <Icon
                className="mt-1 size-6 shrink-0 text-teal-700"
                aria-hidden
              />
              <div>
                <p className="text-2xl font-semibold text-teal-900">{title}</p>
                <p>{description}</p>
              </div>
            </li>
          ))}
        </ul>
      </Interstitial>
    </FullHeightWrapper>
  );
};

const QuestionsComplete = () => {
  const { resolverType } = useParams<ResolverRouteParams>();
  const finishRedirectUrl = getFinishRedirectUrl();
  const location = useResolverLocation();
  const hasNewTasks = useLoaderData() as boolean;
  let continueUrl = '/home';

  if (finishRedirectUrl) {
    continueUrl = finishRedirectUrl;
  } else if (location) {
    continueUrl = `/locations/usa/${location.code}/setup`;
  }
  // TODO: Uncomment this when requirement resolvers are ready to go live
  // } else if (
  //   location &&
  //   resolverType === ResolverType.Assessment &&
  //   hasNewTasks
  // ) {
  //   continueUrl = `/locations/usa/${location.code}/resolver/setup`;
  // }

  let headerText = 'You’re done answering questions!';
  if (resolverType === ResolverType.Review) {
    headerText = 'You’re all set!';
  }

  let descriptionText;
  if (resolverType === ResolverType.Review) {
    descriptionText = 'Thank you for keeping your account up-to-date.';
  } else if (hasNewTasks) {
    descriptionText = `Your answers have generated ${resolverType === ResolverType.Assessment ? 'setup' : ''} tasks tailored to your situation.`;
  } else {
    descriptionText = 'There’s no action required based on your answers.';
  }

  return (
    <>
      <div className="mb-4 max-w-xl space-y-6 text-center">
        {location ? (
          <div className="mx-auto size-40">
            <MutedStateSeal />
          </div>
        ) : (
          <div className="flex flex-col items-center">
            <GenericCompleteGraphic />
          </div>
        )}

        <div className="space-y-1">
          <h1 className="text-4xl font-bold tracking-tight">{headerText}</h1>
          {!finishRedirectUrl && <p>{descriptionText}</p>}
        </div>
      </div>

      <Button size="xlarge" as={Link} to={continueUrl}>
        Continue
      </Button>
    </>
  );
};

const GenericComplete = () => {
  const finishRedirectUrl = getFinishRedirectUrl();

  return (
    <>
      <GenericCompleteGraphic />

      <div className="max-w-xl space-y-6 text-center">
        <h1 className="text-5xl font-black">Great Job!</h1>
        <p className="text-lg font-medium">
          All outstanding tasks have been completed.
        </p>
      </div>

      <Button size="xlarge" as={Link} to={finishRedirectUrl ?? '/home'}>
        Finish
      </Button>
    </>
  );
};

export const Component = () => {
  const { resolverType } = useParams<ResolverRouteParams>();
  const todoTasks = useTodoTasks();
  const resolverUrl = useResolverUrl();

  if (todoTasks.length > 0) {
    return <Navigate to={resolverUrl || '/home'} replace />;
  }

  if (resolverType === ResolverType.Setup) {
    return <StateSetupComplete />;
  }

  return (
    <div className="flex h-full max-w-screen-2xl @container/task">
      <ResolverSidebar />

      <section className="mb-24 flex size-full flex-col items-center justify-center gap-y-6 self-center overflow-y-auto">
        {resolverType === ResolverType.Assessment ||
        resolverType === ResolverType.Question ||
        resolverType === ResolverType.Review ? (
          <QuestionsComplete />
        ) : (
          <GenericComplete />
        )}
      </section>
    </div>
  );
};
