import { components } from '@mosey/api-types';
import { Badge } from '../alerts/Badge';
import { ReactivateConnectionButton } from './ReactivateConnectionButton';

interface IntegrationsProps {
  connections: components['schemas']['Connection'][];
}

export const Integrations = ({ connections }: IntegrationsProps) => {
  return (
    <ul className="mt-6 divide-y rounded-lg border border-gray-200 text-gray-700">
      {connections.map((connection) => {
        const {
          id,
          provider_name: providerName,
          integration,
          is_disabled: isDisabled,
          needs_auth: needsAuth,
        } = connection;

        return (
          <li key={id} className="flex items-center justify-between px-4 py-5">
            <p>
              {providerName
                ? `${providerName}${
                    integration.slug === 'csv-import'
                      ? ` (${integration.name})`
                      : ''
                  }`
                : integration.name}
            </p>

            <div className="flex items-center gap-x-4">
              {needsAuth && (
                <ReactivateConnectionButton connection={connection} />
              )}

              <Badge isActive={!(isDisabled || needsAuth)}>
                {isDisabled || needsAuth ? 'Inactive' : 'Active'}
              </Badge>
            </div>
          </li>
        );
      })}
    </ul>
  );
};
