import { FunctionComponent, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { Button } from '@mosey/components/buttons/Button';
import { FormError } from '@mosey/components/forms/FormError';
import { PageHeader } from '@mosey/components/page-header/PageHeader';
import { PageTitle } from '@mosey/components/page-header/PageTitle';
import { Section, Confirmation } from '../components';
import { fetchApi } from '../utils/fetchApi';
import { ApiStatus, IApiData } from '../utils/types';
import { Renderer, formSpecToRendererConfig } from '../components/forms';

interface FormValues {
  email: string;
}

export const InviteView: FunctionComponent = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasSubmitError, setHasSubmitError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isSubmitSuccessful, setSubmitSuccessful] = useState<boolean>(false);

  const formMethods = useForm<FormValues>();
  const {
    handleSubmit,
    formState: { errors },
  } = formMethods;

  const onSubmit = (data: FormValues) => {
    setErrorMessage(null);
    setHasSubmitError(false);
    setIsLoading(true);

    fetchApi({
      url: `/api/invites/send`,
      method: 'POST',
      body: data,
    }).then(({ status, error }: IApiData) => {
      if (status === ApiStatus.Error) {
        // Handle errors thrown from backend
        if (error.detail && error.detail === 'Invalid username domain') {
          setErrorMessage(
            'Please ensure you provided a valid company email address',
          );
        } else if (error.detail) {
          setErrorMessage(error.detail);
        } else {
          setHasSubmitError(true);
        }
      } else {
        setSubmitSuccessful(true);
      }
    });
    setIsLoading(false);
  };

  const handleGoToSettingsButtonClick = (e: React.MouseEvent) => {
    e.preventDefault();
    navigate('/settings');
  };

  const spec = {
    title: 'Invite Form',
    sections: [
      {
        form_fields: [
          {
            name: 'email',
            label: 'Email address',
            component: {
              type: 'text',
              params: {
                placeholder: 'me@example.com',
                type: 'email',
              },
            },
            required: 'This is required',
          },
        ],
      },
    ],
  };

  return (
    <>
      <PageHeader>
        <PageTitle>Send Invite</PageTitle>
      </PageHeader>

      <Section className="flex flex-col items-center">
        {isSubmitSuccessful ? (
          <div className="flex flex-col justify-center">
            <Confirmation
              heading="Sent"
              description="Your invite is on its way!"
              buttonText="Back to settings"
              onButtonClick={handleGoToSettingsButtonClick}
            />
          </div>
        ) : (
          <>
            <FormProvider {...formMethods}>
              <form className="my-8 w-96" onSubmit={handleSubmit(onSubmit)}>
                <Renderer
                  config={formSpecToRendererConfig(spec)}
                  errors={errors}
                />
                <div className="mt-4 flex-col items-end">
                  <Button
                    type="submit"
                    size="large"
                    isLoading={isLoading}
                    isDisabled={isLoading}
                  >
                    Invite
                  </Button>
                  {errorMessage && <FormError errorMessage={errorMessage} />}
                  {hasSubmitError && <FormError />}
                </div>
              </form>
            </FormProvider>
          </>
        )}
      </Section>
    </>
  );
};
