import { PolicyItem } from '../types';
import { usePolicySection } from './usePolicySection';

export function usePolicy(sectionId: string, slug: string) {
  const { currentSection } = usePolicySection(sectionId);

  const policy = currentSection.items.find(
    (item) => item.type === 'policy' && item.slug === slug,
  ) as PolicyItem | undefined;

  return { policy, currentSection };
}
