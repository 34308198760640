import { CheckCircleIcon } from '@heroicons/react/solid';
import { getStatusIconData } from '@mosey/components/layout/StatusIcon';
import { QueryFilter } from '@mosey/components/menus/QueryFilter';
import { RobotWithRefreshIcon } from '@mosey/components/Icons';

export const TasksOverviewStatusFilter = () => {
  return (
    <QueryFilter
      queryKey="status"
      options={[
        {
          label: 'To do',
          value: 'todo',
          icon: getStatusIconData('new'),
        },
        {
          label: 'Overdue',
          value: 'overdue',
          icon: getStatusIconData('overdue'),
        },
        {
          label: 'Done',
          value: 'done',
          icon: {
            Component: CheckCircleIcon,
            baseColor: 'text-teal-700',
          },
        },
        {
          label: 'Automated',
          value: 'automated',
          icon: getStatusIconData('automated'),
        },
        {
          label: 'Managed',
          value: 'managed',
          icon: {
            Component: RobotWithRefreshIcon,
            baseColor: 'text-violet-600',
          },
        },
      ]}
    />
  );
};
