import Markdown from 'marked-react';
import { PolicyMainContent } from './types';
import { forwardRef } from 'react';

type PolicyMarkdownProps = {
  content: PolicyMainContent;
};

export const PolicyMarkdown = forwardRef<HTMLDivElement, PolicyMarkdownProps>(
  ({ content }, ref) => {
    return (
      <div className="handbook-markdown flex flex-col gap-4" ref={ref}>
        <Markdown
          renderer={{
            link(href, text) {
              if (href.startsWith('$')) {
                return (
                  <abbr key={href} title={text as string}>
                    {href.substring(1)}
                  </abbr>
                );
              } else {
                return (
                  <a
                    href={href}
                    target="_blank"
                    rel="noopener noreferrer"
                    key={href}
                  >
                    {text}
                  </a>
                );
              }
            },
          }}
          value={content.content}
        />
      </div>
    );
  },
);
PolicyMarkdown.displayName = 'PolicyMarkdown';
