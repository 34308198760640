import { FunctionComponent, ReactNode } from 'react';
import { DownloadIcon, MessageIcon } from '@mosey/components/Icons';
import { LegalEntityDocument } from '../types/api';
import { formatDateFromString } from '../utils/format';
import { fetchApi } from '../utils/fetchApi';
import { ApiStatus, IApiData } from '../utils/types';
import { Loading } from '../views/Loading';
import { Button } from '@mosey/components/buttons/Button';
import { MailPreview } from './Inbox';
import { APP_BASE_URL } from '../settings/config';
import { PageHeader } from '@mosey/components/page-header/PageHeader';
import { PageTitle } from '@mosey/components/page-header/PageTitle';

export type DocumentsProps = {
  documents: LegalEntityDocument[] | null;
};

export const Documents: FunctionComponent<DocumentsProps> = ({ documents }) => {
  let content;

  if (documents === null) {
    content = <Loading />;
  } else if (documents.length === 0) {
    content = (
      <InboxMessage
        icon={<MessageIcon />}
        title="Document not found"
        description="Sorry, we couldn't find the document you requested."
      />
    );
  } else {
    content = (
      <div className="flex grow overflow-hidden">
        <InboxWithDocuments
          documents={documents}
          documentId={documents[0].id}
        />
      </div>
    );
  }

  return (
    <>
      <PageHeader>
        <PageTitle>Documents</PageTitle>
      </PageHeader>

      <div className="size-full bg-gray-50 p-6">
        <div className="h-full border bg-white">
          <div className="flex h-full flex-col">{content}</div>
        </div>
      </div>
    </>
  );
};

type InboxMessageProps = {
  title: string;
  description: string | ReactNode;
  icon: ReactNode;
};

const InboxMessage: FunctionComponent<InboxMessageProps> = ({
  title,
  description,
  icon,
}) => {
  return (
    <div className="flex h-full flex-col items-center justify-center bg-white">
      <div className="max-w-md">
        <div className="mb-6 size-10 rounded bg-gray-100 p-2 text-gray-500">
          {icon}
        </div>
        <div className="mb-6">
          <h3 className="text-xl font-bold">{title}</h3>
          <p className="text-gray-500">{description}</p>
        </div>
      </div>
    </div>
  );
};

type InboxWithDocumentsProps = {
  documents: LegalEntityDocument[];
  documentId: string;
};

const InboxWithDocuments: FunctionComponent<InboxWithDocumentsProps> = ({
  documents,
  documentId,
}) => {
  const doc = documents.find((doc) => doc.id === documentId);

  if (!doc) {
    return null;
  }

  const onDownloadClick = () => {
    // Download the document to the user's computer
    // Since the docUrl is a signed S3 link, we refresh it here
    fetchApi({
      url: `/api/documents/download/${doc.id}`,
      method: 'GET',
      contentType: '',
    }).then(({ status, data }: IApiData) => {
      if (status === ApiStatus.Success) {
        const objectURL = URL.createObjectURL(data);
        const alink = document.createElement('a');
        alink.href = objectURL;
        alink.download = doc.document_type;
        alink.click();
      }
    });
  };

  return (
    <div data-testid="mailroom-mail-preview" className="flex grow flex-col">
      <div className="flex min-h-[70px] items-center justify-between gap-4 border-b px-6 py-4">
        <h2 className="font-semibold">
          {doc.region_code} - {doc.document_type}
        </h2>
        <p className="text-sm leading-6 text-gray-400">
          {formatDateFromString(doc.uploaded_on)}
        </p>

        <div className="ml-auto flex items-center gap-2">
          <div>
            <Button
              size="small"
              variant="secondary"
              onClick={onDownloadClick}
              isFullWidth
              rightIcon={<DownloadIcon className="ml-2 w-4" />}
            >
              Download
            </Button>
          </div>
        </div>
      </div>
      <div className="flex flex-1 flex-col overflow-auto px-6 pb-8 pt-4">
        <div className="flex w-full flex-1 flex-col border-teal-100 bg-white">
          <MailPreview
            url={`${APP_BASE_URL}/api/documents/download/${doc.id}`}
          />
        </div>
      </div>
    </div>
  );
};
