import { useRouteLoaderData } from 'react-router-dom';
import { AttributeValues } from '../types';

export function usePolicySectionAttributeValues() {
  const customCategoryValues = useRouteLoaderData(
    'custom-category-policy',
  ) as AttributeValues;
  const policySectionValues = useRouteLoaderData(
    'policy-section-details',
  ) as AttributeValues;
  return policySectionValues || customCategoryValues || {};
}
