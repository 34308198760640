import { FunctionComponent, ReactNode, useEffect, useState } from 'react';
import { useSearchParams, useMatch, Link } from 'react-router-dom';
import { clsx } from 'clsx';
import {
  CheckIcon,
  DownloadIcon,
  ErrorIcon,
  LoadingIcon,
  MessageIcon,
  RobotIcon,
} from '@mosey/components/Icons';
import { Button } from '@mosey/components/buttons/Button';
import { Pill } from '@mosey/components/badges/Pill';
import {
  Mail,
  MailClassification,
  MailStatus,
  MailType,
  RequirementPatchUpdate,
  User,
} from '../types/api';
import { formatDateFromString, nameFromUser } from '../utils/format';
import { fetchApi } from '../utils/fetchApi';
import { ApiStatus, IApiData } from '../utils/types';
import { Loading } from '../views/Loading';
import AssignSelect from './AssignSelect';
import { DateRangePicker } from './dates/DateRangePicker';

type InboxMessageProps = {
  title: string;
  description: string | ReactNode;
  icon: ReactNode;
};

export const InboxMessage: FunctionComponent<InboxMessageProps> = ({
  title,
  description,
  icon,
}) => {
  return (
    <div className="flex h-full flex-col items-center justify-center bg-white">
      <div className="max-w-md">
        <div className="mb-6 size-10 rounded bg-gray-100 p-2 text-gray-500">
          {icon}
        </div>
        <div className="mb-6">
          <h3 className="text-xl font-bold">{title}</h3>
          <p className="text-gray-500">{description}</p>
        </div>
      </div>
    </div>
  );
};

export type MailPreviewProps = {
  url: string;
};

export const MailPreview: FunctionComponent<MailPreviewProps> = ({ url }) => {
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const [isError, setIsError] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    setIsError(false);
    setIsLoading(true);

    // The URL returned by the Mail API includes the hostname so we need
    // to remove it in order to use it with fetchAPI
    const path = new URL(url).pathname;

    // Download the pdf and generate an object URL that can be used by
    // the iframe to view the file
    fetchApi({ url: path, method: 'GET', contentType: '' }).then(
      ({ status, data }: IApiData) => {
        if (status === ApiStatus.Success) {
          const objectURL = URL.createObjectURL(data);
          setPreviewUrl(objectURL);
        } else {
          setIsError(true);
        }
        setIsLoading(false);
      },
    );
  }, [url]);

  if (isError) {
    return (
      <InboxMessage
        icon={<ErrorIcon />}
        title="Preview unavailable"
        description={
          <span className="mt-4 block">
            <span>
              A preview scan is not yet available. Please allow 24 hours for the
              scan to complete or contact us if the issue persists.
            </span>
          </span>
        }
      />
    );
  }

  if (!previewUrl) {
    return <Loading />;
  }

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <iframe
          src={`${previewUrl}#toolbar=0`}
          title={url}
          className="w-full flex-1"
        />
      )}
    </>
  );
};

const MailTypeToName: Record<MailType, string> = {
  [MailType.RegisteredAgent]: 'Registered Agent',
  [MailType.ExternalMail]: 'Mailroom',
};

export type InboxWithMailProps = {
  messages: Mail[];
  users: User[];
  toggleReloadView: () => void;
  mailId: string;
};

export const InboxWithMail: FunctionComponent<InboxWithMailProps> = ({
  messages,
  users,
  toggleReloadView,
  mailId,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [isAssignmentLoading, setIsAssignmentLoading] =
    useState<boolean>(false);
  const [isStatusLoading, setIsStatusLoading] = useState<boolean>(false);

  const mail = messages.find((m) => m.id === mailId);

  useEffect(() => {
    if (!mail) {
      searchParams.delete('mail_id');
      setSearchParams(searchParams, { replace: true });
    }
  }, [mail, searchParams, setSearchParams]);

  useEffect(() => {
    if (mail && !mail.is_read) {
      fetchApi({
        url: `/api/legal_entity/mail/${mail.id}`,
        method: 'PATCH',
        body: { operation: 'read_state', is_read: true },
      });
    }
  }, [mail]);

  if (!mail) {
    return null;
  }

  const handleAssignmentChange = (userId: string | null) => {
    setIsAssignmentLoading(true);

    let body: RequirementPatchUpdate;
    if (userId) {
      body = {
        operation: 'assign',
        // eslint-disable-next-line
        user_id: userId,
      };
    } else {
      body = {
        operation: 'unassign',
      };
    }
    fetchApi({
      url: `/api/legal_entity/mail/${mail.id}`,
      method: 'PATCH',
      body,
    }).then(() => toggleReloadView());

    // Add a timeout here so it never flashes on the screen
    setTimeout(() => setIsAssignmentLoading(false), 400);
  };

  const handleToggleStatus = () => {
    setIsStatusLoading(true);

    fetchApi({
      url: `/api/legal_entity/mail/${mail.id}`,
      method: 'PATCH',
      body: {
        operation: 'status_update',
        status:
          mail.status === MailStatus.Unresolved
            ? MailStatus.Resolved
            : MailStatus.Unresolved,
      },
    }).then(() => toggleReloadView());

    // Add a timeout here so it never flashes on the screen
    setTimeout(() => setIsStatusLoading(false), 400);
  };

  const onDownloadClick = () => {
    const path = new URL(mail.url).pathname;
    const mailTitle = `${mail.sender}-${mail.id}.pdf`;

    // Download the mail pdf to the user's computer
    fetchApi({ url: path, method: 'GET', contentType: '' }).then(
      ({ status, data }: IApiData) => {
        if (status === ApiStatus.Success) {
          const objectURL = URL.createObjectURL(data);
          const alink = document.createElement('a');
          alink.href = objectURL;
          alink.download = mailTitle;
          alink.click();
        }
      },
    );
  };

  return (
    <div className="flex grow overflow-hidden">
      <div className="w-80 min-w-80 overflow-auto">
        {messages.map((m) => {
          searchParams.set('mail_id', m.id);

          return (
            <Link
              key={m.source_id}
              className={clsx('block border-b p-4', {
                'border-b bg-teal-200': m.source_id === mail.source_id,
                'cursor-pointer hover:bg-teal-200':
                  m.source_id !== mail.source_id,
              })}
              to={`${window.location.pathname}?${searchParams.toString()}`}
            >
              <div className="mb-1 flex items-start space-x-2">
                {m.status === MailStatus.Resolved ? (
                  <span className="mt-1 size-4 rounded-full border-transparent bg-lime-500">
                    <CheckIcon className="size-4 text-white" />
                  </span>
                ) : (
                  <span className="mt-1 size-4 rounded-full border bg-gray-100" />
                )}

                <h4
                  className={clsx('line-clamp-3 flex-1 text-sm font-bold', {
                    'text-teal-800': m.source_id === mail.source_id,
                    'text-teal-700': m.source_id !== mail.source_id,
                  })}
                >
                  {m.sender
                    ? m.sender.replaceAll('\n', '')
                    : MailTypeToName[m.type]}
                </h4>
                <time
                  dateTime={m.received_at}
                  className="text-xs text-gray-400"
                >
                  {formatDateFromString(m.received_at)}
                </time>
              </div>
              <div className="ml-6 space-y-1">
                {m.description && <p className="text-sm">{m.description}</p>}

                <div className="text-xs text-gray-400">
                  <p>Source: {m.region_code.toUpperCase()}</p>
                  {m.assigned_user && (
                    <p>Assigned to {nameFromUser(m.assigned_user)}</p>
                  )}
                </div>
              </div>
            </Link>
          );
        })}
      </div>
      <div
        data-testid="mailroom-mail-preview"
        className="flex grow flex-col border-l"
      >
        <div className="flex items-center justify-between border-b p-4">
          <span className="text-sm font-semibold text-gray-500">Status</span>
          <span className="ml-2 mr-4 inline-block border-r pr-4">
            <Pill
              variant={
                mail.status === MailStatus.Resolved ? 'success' : 'inactive'
              }
              size="small"
            >
              {mail.status}
            </Pill>
          </span>
          <span className="inline text-sm font-semibold text-gray-500">
            Assigned to
          </span>
          <span className="ml-2">
            <LoadingIcon
              className={clsx(
                'mr-1 inline size-5 cursor-pointer text-gray-400',
                {
                  hidden: !isAssignmentLoading,
                },
              )}
            />
            <AssignSelect
              users={users}
              assignedTo={mail.assigned_user}
              onChange={handleAssignmentChange}
              alignRight
            />
          </span>

          <div className="ml-auto flex items-center gap-2">
            <Button
              size="small"
              variant="secondary"
              onClick={onDownloadClick}
              isFullWidth
              rightIcon={<DownloadIcon className="ml-2 w-4" />}
            >
              Download
            </Button>

            <Button
              size="small"
              variant={
                mail.status === MailStatus.Resolved ? 'secondary' : 'primary'
              }
              onClick={handleToggleStatus}
              isFullWidth
              isLoading={isStatusLoading}
            >
              {mail.status === MailStatus.Resolved
                ? 'Unresolve'
                : 'Mark resolved'}
            </Button>
          </div>
        </div>
        <div className="flex flex-col border-b bg-violet-50 px-6 py-4 text-sm">
          <div className="flex">
            <dl className="grid flex-1 grid-cols-2">
              {[
                { title: 'From', value: mail.summary.sender },
                { title: 'Re', value: mail.summary.title },
                { title: 'To', value: mail.summary.recipient },
                { title: 'Sent', value: mail.summary.sent_date },
              ]
                .filter(({ value }) => !!value)
                .map(({ title, value }) => (
                  <div className="flex gap-2" key={title}>
                    <dt className="font-bold">{title}:</dt>
                    <dd className="line-clamp-1" title={value || ''}>
                      {value}
                    </dd>
                  </div>
                ))}
            </dl>
            {mail.summary.classification ==
              MailClassification.TaxRateChange && (
              <Pill variant="automation">
                {mail.summary.classification.replaceAll('_', ' ')}
              </Pill>
            )}
          </div>
          <div className="mt-4 rounded bg-violet-200 p-4">
            {mail.summary.explanation}
          </div>
          <div className="mt-3 flex items-center space-x-1 text-xs font-semibold text-violet-500">
            <RobotIcon className="w-4" />
            <div>Mosey AI Generated Summary</div>
            <div className="flex-1 text-right font-normal">
              AI-powered summaries can make mistakes. Check important
              information.
            </div>
          </div>
        </div>

        <MailPreview url={mail.url} />
      </div>
    </div>
  );
};

export type InboxProps = {
  messages: Mail[];
  users: User[];
  toggleReloadView: () => void;
  mailId?: string;
};

export const Inbox: FunctionComponent<InboxProps> = ({
  messages,
  users,
  toggleReloadView,
  mailId,
}) => {
  // This component handles three different scenarios and delegates to
  // other components. This makes it easier to reason about and avoids
  // the need to constantly null check `mailId` and `messages`.
  // - There are messages and selected mail
  // - There are no messages
  // - There are messages but no selected mail

  const isGlobal = useMatch('/mail');
  const [searchParams, setSearchParams] = useSearchParams();

  // Handle selecting the first message if loading the root URL
  useEffect(() => {
    if (!mailId && messages.length > 0) {
      searchParams.set('mail_id', messages[0].id);
      setSearchParams(searchParams, { replace: true });
    } else if (mailId && messages.length === 0) {
      searchParams.delete('mail_id');
      setSearchParams(searchParams, { replace: true });
    }
  }, [mailId, messages, searchParams, setSearchParams]);

  let content = <Loading />;

  if (messages.length === 0) {
    content = (
      <InboxMessage
        icon={<MessageIcon />}
        title="No Messages Yet"
        description="Once you receive mail it will show up here."
      />
    );
  } else if (mailId) {
    content = (
      <InboxWithMail
        messages={messages}
        users={users}
        mailId={mailId}
        toggleReloadView={toggleReloadView}
      />
    );
  }

  return (
    <>
      {!isGlobal && (
        <header className="flex items-center border-b p-4">
          <h3 className="font-bold">Inbox</h3>

          <div className="ml-auto flex gap-x-4">
            <DateRangePicker
              defaultStartDate={searchParams.get('start_date')}
              defaultEndDate={searchParams.get('end_date')}
              ariaButtonTextPrefix="Change mail date filter:"
              defaultButtonText="All Time"
              onRangeChange={(newStartDate, newEndDate) => {
                if (newStartDate) {
                  searchParams.set('start_date', newStartDate);
                } else {
                  searchParams.delete('start_date');
                }

                if (newEndDate) {
                  searchParams.set('end_date', newEndDate);
                } else {
                  searchParams.delete('end_date');
                }

                setSearchParams(searchParams);
              }}
            />
          </div>
        </header>
      )}

      {content}
    </>
  );
};
