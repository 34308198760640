import { PlatformNav } from './PlatformNav';
import { useUser } from '../../hooks/useUser';
import AppNav from './AppNav';

interface NavProps {
  children: React.ReactNode;
}

export const Nav = ({ children }: NavProps) => {
  const user = useUser();

  if (user.is_platform_user) {
    return <PlatformNav>{children}</PlatformNav>;
  }

  return <AppNav>{children}</AppNav>;
};
