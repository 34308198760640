import {
  FunctionComponent,
  forwardRef,
  useState,
  useCallback,
  MouseEvent,
  useEffect,
  useMemo,
  Dispatch,
  SetStateAction,
  createElement,
  Suspense,
} from 'react';
import {
  useNavigate,
  useLocation,
  useSearchParams,
  useRevalidator,
  useAsyncValue,
  useRouteLoaderData,
  Await,
} from 'react-router-dom';
import { clsx } from 'clsx';
import { icon as iconStyle } from '@mosey/components/menus/styles';
import { DropdownMenu, MenuItem } from '@mosey/components/menus/DropdownMenu';
import { TextLink } from '@mosey/components/navigation/TextLink';
import { Button } from '@mosey/components/buttons/Button';
import {
  ArrowRightIcon,
  CheckIcon,
  ClipboardCheckIcon,
  SparkleIcon,
  GovernmentIcon,
  HumanResourcesIcon,
  LinkIcon,
  LoadingIcon,
  LockClosedFilledIcon,
  MoreIcon,
  PayrollIcon,
  RefreshIcon,
  RobotIcon,
  StopwatchIcon,
  TaxIcon,
} from '@mosey/components/Icons';
import { useScrollIntoView } from '@mosey/components/hooks';
import { Pill } from '@mosey/components/badges/Pill';
import {
  AssignSelect,
  BatchApiStatusHandler,
  NavigatorSectionItemNew,
  NavigatorSectionNew,
  TextTruncateThree,
  RequirementProgressNew,
} from '../components';
import { useBatchApi } from '../hooks';
import {
  AutomationTypeEnum,
  Requirement,
  RequirementStatus,
  LegalEntityRegion,
  User,
  RegionNavigator,
  RequirementComputedStatus,
  TaxClassEnum,
  RequirementCategory,
  Remediation,
  FormSpec,
  RequirementPatchUpdate,
  Resource,
} from '../types';
import {
  isDeferred as requirementIsDeferred,
  isSetupRequirementNextable,
  isDone as requirementIsDone,
  isManagedByMosey as requirementIsManagedByMosey,
  statusColorMapping,
  isSkipped as requirementIsSkipped,
  ManagedPayrollReqsByState,
} from '../utils/requirement';
import {
  formatDateFromString,
  requirementComputedStatus,
} from '../utils/format';
import { fetchApi } from '../utils/fetchApi';
import { ApiStatus, IApiData } from '../utils/types';
import { useLocationDetailContext } from '../contexts/locationDetailContext';
import { FatalError } from '../views/FatalError';
import { FormAutomation } from '../components/forms/FormAutomation';
import {
  PromptMessage,
  PromptMessageTypeEnum,
  Confirmation,
  ConfirmationTypeEnum,
  RequirementQuestions,
  SectionProgress,
} from './location_detail_setup_new';
import { useUser } from '../hooks/useUser';
import { NoticeUrlDisplay } from './Notices_NEW';
import { isHandbookRequirement } from './handbook/utils';
import { PolicyInstance } from './handbook/types';
import { CopyablePolicyPreview } from '../components/requirement/CopyablePolicyPreview';
import { TaskSummaryCard } from '@mosey/components/layout/Card';
import { USStateName } from '@mosey/utils/constants/us-states';
import {
  TaskQuestionRef,
  TaskRef,
  TaskType,
} from './tasks-framework/utils/types';
import { LocationDetailViewAssessmentInterstitial } from './LocationDetailInterstitials';
import { QuestionTasksBanner } from '../components/QuestionTasksBanner';
import { Loading } from './Loading';

export enum SetupSectionId {
  Payroll = 'payroll',
  SalesTax = 'sales-tax',
  CorpTax = 'corp-tax',
  HR = 'hr',
  HRAndBenefits = 'hr-benefits',
  Registration = 'registration',
}

const SetupSectionIdToName: Record<SetupSectionId, string> = {
  [SetupSectionId.Payroll]: 'Payroll',
  [SetupSectionId.SalesTax]: 'Sales Tax',
  [SetupSectionId.CorpTax]: 'Corporate Tax',
  [SetupSectionId.HR]: 'HR',
  [SetupSectionId.HRAndBenefits]: 'HR & Benefits',
  [SetupSectionId.Registration]: 'Registration',
};

const SetupSectionIdToIcon: Record<SetupSectionId, React.FunctionComponent> = {
  [SetupSectionId.Payroll]: PayrollIcon,
  [SetupSectionId.SalesTax]: TaxIcon,
  [SetupSectionId.CorpTax]: TaxIcon,
  [SetupSectionId.HR]: HumanResourcesIcon,
  [SetupSectionId.HRAndBenefits]: HumanResourcesIcon,
  [SetupSectionId.Registration]: GovernmentIcon,
};

export const statusIconMappingNew: Record<
  RequirementComputedStatus,
  FunctionComponent
> = {
  [RequirementComputedStatus.Todo]: ClipboardCheckIcon,
  [RequirementComputedStatus.Done]: CheckIcon,
  [RequirementComputedStatus.Deferred]: StopwatchIcon,
  [RequirementComputedStatus.Locked]: LockClosedFilledIcon,
  [RequirementComputedStatus.Overdue]: StopwatchIcon,
  [RequirementComputedStatus.InProgress]: RefreshIcon,
  [RequirementComputedStatus.Managed]: RobotIcon,
};

type NextStepDictionary = {
  [key: string]: {
    requirement: Requirement;
    section: Section;
  };
};

export enum RequirementSetupStepEnum {
  RequirementQuestion = 'requirementQuestion',
  AutomationPrompt = 'automationPrompt',
  AutomationForm = 'automationForm',
  CompletedRequirement = 'CompletedRequirement',
  CompletedRequirementPendingImport = 'CompletedRequirementPendingImport',
  CompletedAllRequirements = 'completedAllRequirements',
  Deferred = 'deferred',
  Incomplete = 'incomplete',
  AutomationSuccess = 'automationSuccess',
  HandbookAutomationSuccess = 'handbookAutomationSuccess',
  EnterLaterSuccess = 'enterLaterSuccess',
  Managed = 'managed',
}

type DynamicAutomationProps = {
  forms?: FormSpec[];
  automation_url?: string;
};

export type Section = {
  id: SetupSectionId;
  requirements: string[];
};

type RequirementContentWrapperProps = {
  title: string;
  description: string;
  resources: Resource[];
  children: React.ReactNode;
};

export const findSectionFromSetupSectionId = (
  sections: Section[],
  setupSectionId: SetupSectionId,
): Section | null => {
  if (!sections.length) {
    return null;
  }

  for (let i = 0; i < sections.length; i += 1) {
    const section = sections[i];

    if (section.id === setupSectionId) {
      return section;
    }
  }

  return null;
};

export const findSectionFromRequirementId = (
  sections: Section[],
  requirementId: string,
): Section | null => {
  if (!sections.length) {
    return null;
  }

  for (let i = 0; i < sections.length; i += 1) {
    const section = sections[i];

    if (section.requirements.includes(requirementId)) {
      return section;
    }
  }

  return null;
};

/**
 * Determines the next requirement that needs attention (e.g. not done,
 * deferred, skipped, or assigned to a user) for a location's setup section.
 */
export const findNextRequirementFromSection = (
  section: Section | null,
  requirements: Requirement[],
): string | null => {
  if (!section) {
    return null;
  }

  for (let i = 0; i < section.requirements.length; i += 1) {
    const requirement = requirements.find(
      (r) => r.data_id === section.requirements[i],
    );

    if (requirement && isSetupRequirementNextable(requirement)) {
      return requirement.data_id;
    }
  }

  return null;
};

/**
 * Determines the next requirement that needs attention (e.g. not done,
 * deferred, skipped, or assigned to a user) by iterating over all of setup
 * sections for a particular location. It calls findNextRequirementFromSection
 * on each section.
 */
export const findNextSectionIdAndRequirementId = (
  sections: Section[],
  requirements: Requirement[],
): [SetupSectionId, string | null] => {
  for (let i = 0; i < sections.length; i += 1) {
    const section = sections[i];
    const requirementId = findNextRequirementFromSection(section, requirements);

    if (requirementId !== null) {
      return [section.id, requirementId];
    }
  }

  return [
    sections[0].id,
    requirements.find((r) => r.data_id === sections[0].requirements[0])
      ?.data_id || null,
  ];
};

const RequirementContentHeader = ({
  description,
  resources,
}: {
  description: string;
  resources: Resource[];
}) => (
  <>
    <TextTruncateThree text={description} />
    <div className="mt-2">
      {resources && (
        <ul className="list-disc">
          {resources.map((resource) => (
            <li key={`${resource.url}`} className="flex text-sm">
              <LinkIcon className="mb-1 me-2 size-4 shrink-0" />
              <TextLink to={resource.url} target="_blank">
                {resource.name}
              </TextLink>
            </li>
          ))}
        </ul>
      )}
    </div>
  </>
);

export const RequirementContentWrapper = forwardRef<
  HTMLDivElement,
  RequirementContentWrapperProps
>(function RequirementContentWrapper(
  { title, description, resources, children },
  ref,
) {
  return (
    <div className="p-16" ref={ref}>
      <h2 className="text-4xl font-black">{title}</h2>

      <div className="py-9">
        <div className="flex items-center gap-x-2">
          <span className="relative shrink-0 text-lg font-medium">
            Requirement
          </span>
          <span className="inline-block h-px w-full bg-gray-200" />
        </div>

        <div className="mt-4">
          <RequirementContentHeader
            description={description}
            resources={resources}
          />
        </div>
      </div>

      <div className="rounded-sm bg-sage-100 p-9">{children}</div>
    </div>
  );
});

type ManagedRequirementProps = {
  requirementData: Requirement;
  onContinue: () => void;
};

export const ManagedRequirement: FunctionComponent<ManagedRequirementProps> = ({
  requirementData,
  onContinue,
}) => {
  const [scrollIntoViewRef] = useScrollIntoView();
  return (
    <RequirementContentWrapper
      title={requirementData.title}
      description={requirementData.description}
      resources={requirementData.resources}
      ref={scrollIntoViewRef}
    >
      <div className="flex rounded bg-sage-400 p-9">
        <div className="mr-10">
          <div className="flex items-center rounded-full bg-indigo-100 p-4 text-indigo-600">
            <RobotIcon className="size-32" />
          </div>
        </div>
        <div className="flex flex-col py-4">
          <div className="text-lg font-bold">You are all set here</div>
          {requirementData.managed_provider && (
            <div className="mt-4">
              This requirement is managed by{' '}
              <span className="font-bold">
                {requirementData.managed_provider.name}
              </span>
            </div>
          )}
          <Button
            className="mt-6 w-48"
            rightIcon={<ArrowRightIcon className="w-4" />}
            size="xlarge"
            onClick={onContinue}
          >
            Continue
          </Button>
        </div>
      </div>
    </RequirementContentWrapper>
  );
};

export type NextStepDataProps = { requirement: Requirement; section: Section };

type RequirementSetupProps = {
  nextStepDictionary: NextStepDictionary;
  requirementData: Requirement;
  users: User[];
  legalEntityRegion: LegalEntityRegion;
  locationId: string;
  setHasSeenSuccessPage: Dispatch<SetStateAction<boolean>>;
  isAutomatable: boolean;
  isImportable: boolean;
  topElementRef: React.RefObject<HTMLDivElement | null>;
  nextStepData: NextStepDataProps | null;
  handleSuccessRefresh: () => void;
  handleNotApplicable: (event?: MouseEvent) => Promise<void>;
  handleSetupComplete: () => void;
};

const RequirementSetup: FunctionComponent<RequirementSetupProps> = ({
  nextStepDictionary,
  requirementData,
  users,
  legalEntityRegion,
  locationId,
  setHasSeenSuccessPage,
  isAutomatable,
  isImportable,
  topElementRef,
  nextStepData,
  handleSuccessRefresh,
  handleNotApplicable,
  handleSetupComplete,
}) => {
  const revalidator = useRevalidator();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [, dispatch] = useLocationDetailContext();
  const { legal_entity: legalEntity } = useUser();

  const [step, setStep] = useState<RequirementSetupStepEnum>(
    RequirementSetupStepEnum.RequirementQuestion,
  );
  const [automationFormType, setAutomationType] =
    useState<AutomationTypeEnum | null>(null);

  useEffect(() => {
    const isManaged =
      requirementData.is_managed &&
      requirementData.managed_provider?.name !== 'Mosey';

    if (isManaged) {
      setStep(RequirementSetupStepEnum.Managed);
    } else {
      setStep(RequirementSetupStepEnum.RequirementQuestion);
    }
  }, [requirementData.id]);

  const resetSetup = useCallback(() => {
    setStep(RequirementSetupStepEnum.RequirementQuestion);
    setHasSeenSuccessPage(false);
  }, []);

  const handleAutomationType = (type: AutomationTypeEnum) => {
    setAutomationType(type);
    setStep(RequirementSetupStepEnum.AutomationForm);
  };

  const handleLastAvailableRequirement = () => {
    setStep(RequirementSetupStepEnum.CompletedAllRequirements);

    if (!legalEntityRegion.is_setup_complete) {
      // Make an API call to update is_setup_complete on LegalEntityRegion
      fetchApi({
        url: `/api/legal_entity/locations/${legalEntityRegion.region.code}/setup_complete`,
        method: 'PUT',
      }).then(({ status }: IApiData) => {
        if (status !== ApiStatus.Error) {
          revalidator.revalidate();
        }
      });
    }
  };

  const handleNextStep = () => {
    if (!nextStepData) {
      handleLastAvailableRequirement();
      return;
    }

    const queryParams = new URLSearchParams({
      section: nextStepData.section.id,
      requirement: nextStepData.requirement.data_id,
    });

    topElementRef?.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'end',
      inline: 'nearest',
    });
    navigate(`${pathname}?${queryParams.toString()}`);
    resetSetup();
  };

  const handleEnterLater = async () => {
    await fetchApi({
      url: `/api/requirements/${requirementData.id}`,
      method: 'PUT',
      body: { status: RequirementStatus.Skipped },
    });

    revalidator.revalidate();

    setStep(RequirementSetupStepEnum.EnterLaterSuccess);
  };

  switch (step) {
    case RequirementSetupStepEnum.Managed:
      return (
        <ManagedRequirement
          requirementData={requirementData}
          onContinue={handleNextStep}
        />
      );
    case RequirementSetupStepEnum.RequirementQuestion:
      return (
        <RequirementQuestions
          requirementData={requirementData}
          locationId={locationId}
          handleNotApplicable={handleNotApplicable}
          isAutomatable={isAutomatable}
          isImportable={isImportable}
          setHasSeenSuccessPage={setHasSeenSuccessPage}
          handleSuccessRefresh={handleSuccessRefresh}
          setStep={setStep}
        />
      );
    case RequirementSetupStepEnum.AutomationPrompt:
      return (
        <PromptMessage
          variant={PromptMessageTypeEnum.Automation}
          title="Put it on Autopilot!"
          description="Mosey can automate tasks like these and many others by having you
          answer a few questions."
          forwardButtonLabel="Use Mosey automation"
          requirementData={requirementData}
          onBack={() => setStep(RequirementSetupStepEnum.RequirementQuestion)}
          onForward={async () => {
            if (!requirementData.id) {
              return;
            }

            const CouldNotAutomateError = Error(
              `Could not automate requirement ${requirementData.id}`,
            );

            // first, check if we have dynamic forms for this automation
            const dynamicAutomationResponse = await fetchApi({
              method: 'GET',
              url: `/api/automation/${requirementData.id}`,
            });

            const { forms, automation_url }: DynamicAutomationProps =
              dynamicAutomationResponse.data;
            if (forms && forms.length && !automation_url) {
              // handle dynamic form automation
              handleAutomationType(AutomationTypeEnum.DynamicForm);
            } else if (!forms && automation_url) {
              // if no dynamic form, find which automation type this is
              const automationResponse = await fetchApi({
                method: 'POST',
                url: `/api/requirements/${requirementData.id}/automate`,
              });

              if (
                automationResponse.status !== ApiStatus.Success ||
                !automationResponse.data.automation_type
              ) {
                throw CouldNotAutomateError;
              }

              handleAutomationType(automationResponse.data.automation_type);
            } else {
              // error if no dynamic or static form automation
              throw CouldNotAutomateError;
            }
          }}
        />
      );

    case RequirementSetupStepEnum.AutomationForm:
      return (
        <FormAutomation
          formType={automationFormType}
          locationId={locationId}
          requirement={requirementData}
          onEnterLater={handleEnterLater}
          onNext={resetSetup}
          nextStepData={nextStepData}
          onSubmitSuccess={() => {
            setStep(
              isHandbookRequirement(requirementData)
                ? RequirementSetupStepEnum.HandbookAutomationSuccess
                : RequirementSetupStepEnum.AutomationSuccess,
            );
            handleSuccessRefresh();
          }}
        />
      );

    case RequirementSetupStepEnum.CompletedRequirement:
      return (
        <Confirmation
          users={users}
          requirement={requirementData}
          variant={ConfirmationTypeEnum.Completed}
          onNext={handleNextStep}
          title="Great Job!"
          description={`You have unlocked recurring requirements related to ${requirementData.title}.`}
          feed={
            requirementData.unblocks?.length
              ? [
                  {
                    title: 'Recurring Activity',
                    subtitle: 'Future Requirements',
                    items: requirementData.unblocks?.map((requirement) => ({
                      title: requirement.title,
                    })),
                  },
                ]
              : null
          }
        />
      );

    case RequirementSetupStepEnum.CompletedRequirementPendingImport:
      return (
        <Confirmation
          users={users}
          requirement={requirementData}
          variant={ConfirmationTypeEnum.Completed}
          handleImportSetup={() => {
            dispatch({ type: 'setIsImporting', payload: true });
            setStep(RequirementSetupStepEnum.RequirementQuestion);
          }}
          onNext={handleNextStep}
          title="Great Job!"
          description={`You have unlocked recurring requirements related to ${requirementData.title}.`}
          feed={
            requirementData.unblocks?.length
              ? [
                  {
                    title: 'Recurring Activity',
                    subtitle: 'Future Requirements',
                    items: requirementData.unblocks?.map((requirement) => ({
                      title: requirement.title,
                    })),
                  },
                ]
              : null
          }
        />
      );

    case RequirementSetupStepEnum.Incomplete:
      return (
        <Confirmation
          users={users}
          requirement={requirementData}
          variant={ConfirmationTypeEnum.Incomplete}
          onNext={handleNextStep}
          title="Don't Mosey Off Yet..."
          description={`Before moving on to the next requirement in ${legalEntityRegion.region.name},
          assign a user so you don't forget to complete ${requirementData.title} later.`}
          feed={[
            {
              title: 'Activation Activity',
              subtitle: 'Remediations to be Completed',
              items: requirementData.remediations.map((remediation) => ({
                title: remediation.title,
              })),
            },
          ]}
        />
      );

    case RequirementSetupStepEnum.AutomationSuccess: {
      const payrollSetupRequirements = ManagedPayrollReqsByState[
        locationId.toLowerCase()
      ].map((requirement) => nextStepDictionary[requirement]);

      const payrollSetupRemediations = payrollSetupRequirements.reduce(
        (acc: Remediation[], requirement) => {
          requirement?.requirement?.remediations?.forEach((remediation) =>
            acc.push(remediation),
          );

          return acc;
        },
        [],
      );

      const automatedRemediations =
        requirementData.category === RequirementCategory.PayrollSetup
          ? payrollSetupRemediations
          : requirementData.remediations;

      const feedItems = [
        {
          title: 'Automated Activity',
          subtitle: 'Automated Requirements',
          items: automatedRemediations.map((remediation) => ({
            title: remediation.title,
          })),
        },
      ];

      if (requirementData.unblocks?.length) {
        feedItems.push({
          title: 'Recurring Activity',
          subtitle: 'Future Requirements',
          items: requirementData.unblocks?.map((remediation) => ({
            title: remediation.title,
          })),
        });
      }

      return (
        <Confirmation
          variant={ConfirmationTypeEnum.Automated}
          users={users}
          requirement={requirementData}
          onNext={handleNextStep}
          title="Ok We're On It!"
          description={`Mosey will take over completing this requirement in ${legalEntityRegion.region.name}.`}
          feed={feedItems}
        />
      );
    }
    case RequirementSetupStepEnum.HandbookAutomationSuccess: {
      if (legalEntity.is_handbook_enabled) {
        return (
          <Confirmation
            variant={ConfirmationTypeEnum.Automated}
            users={users}
            requirement={requirementData}
            onNext={handleNextStep}
            title="Policies added to your handbook!"
            description="You generated the policies below. We'll remind you to keep these policies up to date and shared with your employees."
          >
            <div className="mb-10 flex w-full flex-col items-center px-6">
              <div className="flex w-full max-w-3xl flex-col space-y-8">
                {requirementData.policy_instances &&
                requirementData.policy_instances?.length > 0 ? (
                  <ul className="mt-4 w-full max-w-3xl">
                    {requirementData.policy_instances?.map((policyInstance) => (
                      <li key={policyInstance.id}>
                        <TaskSummaryCard
                          target="_blank"
                          variant="card"
                          to={`/handbook/section/${policyInstance.policy_section.id}/policies#${policyInstance.slug}`}
                          status="done"
                          title={policyInstance.title}
                          region={
                            USStateName[
                              requirementData
                                .region[0] as keyof typeof USStateName
                            ]
                          }
                          reason={{
                            title: 'State Compliance',
                            type: 'new-requirement',
                          }}
                        />
                      </li>
                    ))}
                  </ul>
                ) : (
                  <Loading />
                )}
              </div>
            </div>
          </Confirmation>
        );
      } else {
        return (
          <Confirmation
            variant={ConfirmationTypeEnum.Automated}
            users={users}
            requirement={requirementData}
            onNext={handleNextStep}
            title="Policies Generated!"
            description="
            You generated the policies below. Copy these policies to a location accessible by your employees."
          >
            <div className="mb-10 flex w-full flex-col items-center px-6">
              <div className="flex w-full max-w-3xl flex-col space-y-8">
                {requirementData.policy_instances &&
                requirementData.policy_instances?.length > 0 ? (
                  requirementData.policy_instances?.map(
                    (policyInstance: PolicyInstance) => (
                      <CopyablePolicyPreview
                        key={policyInstance.id}
                        policyInstance={policyInstance}
                      />
                    ),
                  )
                ) : (
                  <Loading />
                )}
              </div>
              <p className="mt-8 max-w-3xl text-center">
                Want to automatically share them with your employees?{' '}
                <TextLink to="/handbook/request">
                  Learn about the employee handbook.
                </TextLink>
              </p>
            </div>
          </Confirmation>
        );
      }
    }

    case RequirementSetupStepEnum.EnterLaterSuccess: {
      return (
        <PromptMessage
          variant={PromptMessageTypeEnum.EnterLater}
          title="Wanna Mosey ahead?"
          description={`Go for it! You can come back to reconfigure ${requirementData.title} when you're ready.`}
          requirementData={requirementData}
          forwardButtonLabel="Continue"
          onBack={() => setStep(RequirementSetupStepEnum.RequirementQuestion)}
          onForward={() => {
            if (!nextStepData) {
              handleLastAvailableRequirement();
              return;
            }

            const queryParams = new URLSearchParams({
              section: nextStepData.section.id,
              requirement: nextStepData.requirement.data_id,
            });

            navigate(`${pathname}?${queryParams.toString()}`);
          }}
        />
      );
    }

    case RequirementSetupStepEnum.Deferred: {
      return (
        <PromptMessage
          variant={PromptMessageTypeEnum.Defer}
          title="Not Your Flavor?"
          description={`We'll defer this requirement to keep it out of your way.
          You can always come back if ${requirementData.title} becomes relevant to you in the future.`}
          requirementData={requirementData}
          forwardButtonLabel="Continue"
          onBack={() => setStep(RequirementSetupStepEnum.RequirementQuestion)}
          onForward={() => {
            if (!nextStepData) {
              handleLastAvailableRequirement();
              return;
            }

            const queryParams = new URLSearchParams({
              section: nextStepData.section.id,
              requirement: nextStepData.requirement.data_id,
            });

            navigate(`${pathname}?${queryParams.toString()}`);

            handleSuccessRefresh();
          }}
        />
      );
    }

    case RequirementSetupStepEnum.CompletedAllRequirements: {
      return (
        <PromptMessage
          variant={PromptMessageTypeEnum.Complete}
          title="Great Job!"
          description={`You have completed configuring ${legalEntityRegion.region.name} in Mosey.`}
          requirementData={requirementData}
          forwardButtonLabel="Review Requirements"
          onBack={() => setStep(RequirementSetupStepEnum.RequirementQuestion)}
          onForward={() => {
            navigate(`${pathname}`);
            handleSetupComplete();
          }}
        />
      );
    }

    default:
      return <></>;
  }
};

type SetupNavigatorProps = {
  // A list of groups to display in the navigator
  sections: Section[];
  // Currently selected section
  setupSectionId: SetupSectionId;
  // Currently selected requirement within the section
  requirementId: string | null;
  // Requirements data to look up if a full `Requirement` instance is needed
  requirements: Requirement[];
  // Cached requirements dictionary
  nextStepDictionary: NextStepDictionary;
  // If all navigator items are blocked
  hasAllItemsBlocked: boolean;
  // If user has dismissed introduction screen
  hasDismissedIntroductionScreen: boolean;
};

export const SetupNavigatorNew: FunctionComponent<SetupNavigatorProps> = ({
  sections,
  setupSectionId,
  requirementId,
  requirements,
  nextStepDictionary,
  hasAllItemsBlocked,
  hasDismissedIntroductionScreen,
}) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  // requirement id can be string or can be null
  // if its null we dont render isselected

  const handleNavigatorSectionClick = (sid: SetupSectionId) => {
    return () => navigate(`${pathname}?section=${sid}`);
  };

  const handleNavigatorSectionItemClick = (
    sid: SetupSectionId,
    rid: string,
  ) => {
    return (e: MouseEvent) => {
      // The parent `NavigatorSection` has a click handler also so this
      // prevents the parent from getting the click event too
      e.stopPropagation();
      navigate(`${pathname}?section=${sid}&requirement=${rid}`);
    };
  };

  // Returns true if all requirements with a data ID in
  // `requirementDataIds` have a status of `RequirementStatus.Done` or
  // they are managed.  NOTE: There can be multiple requirements with
  // the same data ID (recurring requirements) so this check looks at
  // the status of the first instance and ignores the rest. Otherwise
  // we could end up in a situation where the user completed all the
  // requirements but the section is not marked as complete
  const sectionRequirementsDone = (
    requirementDataIds: string[],
    partiallyDone = false,
  ): boolean => {
    const reqs = [];
    const visitedDataIds = new Set();
    for (const r of requirements) {
      const dataId = r.data_id;
      if (requirementDataIds.indexOf(dataId) === -1) {
        continue;
      }

      // Ignore requirements that have a data ID we've already visited
      if (visitedDataIds.has(dataId)) {
        continue;
      }

      visitedDataIds.add(dataId);
      reqs.push(r);
    }

    if (partiallyDone) {
      return reqs.some((r) => requirementIsDone(r));
    }

    return reqs.every((r) => requirementIsDone(r));
  };

  return (
    <nav aria-label="Location Setup Navigator">
      <div className="relative">
        {sections.map((section, sectionIdx) => {
          const sectionHasBlockedItem = section.requirements.some(
            (reqId) =>
              nextStepDictionary[reqId] &&
              nextStepDictionary[reqId].requirement.is_blocked,
          );

          const isFirstSection = sectionIdx === 0;

          const isAllCurrentSectionRequirementsTodo =
            section.requirements.every(
              (requirement) =>
                nextStepDictionary[requirement].requirement.status ===
                RequirementStatus.Todo,
            );

          return (
            <NavigatorSectionNew
              key={section.id}
              sectionIcon={SetupSectionIdToIcon[section.id]}
              heading={SetupSectionIdToName[section.id]}
              isSelected={setupSectionId === section.id}
              isFirst={isFirstSection}
              isPartiallyDone={sectionRequirementsDone(
                section.requirements,
                true,
              )}
              isDone={
                section.requirements.length > 0 &&
                sectionRequirementsDone(section.requirements)
              }
              onClick={
                isAllCurrentSectionRequirementsTodo
                  ? handleNavigatorSectionClick(section.id)
                  : undefined
              }
            >
              {section.requirements.map((reqId: string, reqIdx: number) => {
                const req = nextStepDictionary[reqId].requirement;

                const isPreviousRequirementTodo = !!(
                  nextStepDictionary[section.requirements[reqIdx - 1]]
                    ?.requirement?.status === RequirementStatus.Todo
                );

                const isCurrentRequirementNotTodo =
                  nextStepDictionary[reqId].requirement.status !==
                  RequirementStatus.Todo;

                const isAnyRemediationDone = nextStepDictionary[
                  reqId
                ].requirement.remediations.some(
                  (remediation) => remediation.is_done,
                );

                const isRequirementBlocked = req && req.is_blocked;

                const isSectionBlocked =
                  setupSectionId === section.id && hasAllItemsBlocked;

                const isLocked =
                  !isAnyRemediationDone &&
                  !req.assigned_user &&
                  !(requirementId === reqId) &&
                  (isRequirementBlocked ||
                    isSectionBlocked ||
                    (isPreviousRequirementTodo &&
                      !isCurrentRequirementNotTodo) ||
                    (isAllCurrentSectionRequirementsTodo &&
                      !hasDismissedIntroductionScreen));

                return (
                  <NavigatorSectionItemNew
                    key={reqId}
                    text={req.title}
                    sectionHasBlockedItem={sectionHasBlockedItem}
                    isSectionSelected={setupSectionId === section.id}
                    isLocked={isLocked}
                    isFirst={reqIdx === 0}
                    isDone={req && requirementIsDone(req)}
                    isSelected={requirementId ? requirementId === reqId : false}
                    isLast={reqIdx === section.requirements.length - 1}
                    onClick={handleNavigatorSectionItemClick(section.id, reqId)}
                  />
                );
              })}
            </NavigatorSectionNew>
          );
        })}
      </div>
    </nav>
  );
};

type SectionOverviewCopy = {
  title: string;
  description: string;
  targetAudience: string;
  actionTitle: string;
  actionDescription: string;
};

const overviewCopyBySectionId = (
  sectionId: SetupSectionId,
  regionName: string,
): SectionOverviewCopy => {
  if (sectionId === SetupSectionId.Payroll) {
    return {
      title: 'Payroll Requirements',
      description:
        "Mosey can help you setup and manage requirements critical to running payroll in a state, like Withholding Tax, Unemployment Insurance Tax, Paid Family Leave, Workers' Compensation Insurance, and more!",
      targetAudience: `Employers setting up payroll for the first time, or already have payroll setup in ${regionName}.`,
      actionTitle: 'Review and configure payroll requirements',
      actionDescription: `You can import your existing payroll accounts or start a new payroll registration.`,
    };
  }
  if (sectionId === SetupSectionId.HR) {
    return {
      title: 'Human Resources Requirements',
      description:
        'Mosey can help you setup and manage mandatory labor law and posters, pay transparency compliance, remote employee reimbursement, and more!',
      targetAudience: `Employers who are active in ${regionName}.`,
      actionTitle: 'Review and configure HR requirements',
      actionDescription: `If you have employees in ${regionName}, additional HR requirements may apply.`,
    };
  }
  if (sectionId === SetupSectionId.Registration) {
    return {
      title: 'Secretary of State Registration',
      description:
        'Mosey can help you setup and manage your Secretary of State registration and reporting requirements.',
      targetAudience: `Registering your business with the Secretary of State (SoS) creates several ongoing tax and reporting requirements. Foreign (out-of-state) entities are generally required to register before "doing business" in a state, and most SoS offices do not explicitly define "doing business." When making this business decision, you’ll want to consider the risks of back taxes, penalties, or other enforcement actions. So, you may consider either registering with the SoS anywhere you have employees, or waiting to register until you are notified to do so or until the risk becomes too high.`,
      actionTitle: 'Review and configure registration requirements',
      actionDescription: `Import your Secretary of State registration information or start a new foreign qualification request via automation.`,
    };
  }
  if (sectionId === SetupSectionId.CorpTax) {
    return {
      title: 'Corporate Tax Requirements',
      description:
        'Mosey can help you manage your filing and payment requirements for state business income tax e.g. corporate tax or business privilege tax.',
      targetAudience: `Entities registered with the Secretary of State, or are otherwise doing business in the state, are typically subject to state business income tax. Most states have an additional estimated payment requirement for taxpayers that reach a certain threshold.`,
      actionTitle: 'Review and configure corporate tax requirements',
      actionDescription: `Import your business tax information and review the threshold for estimated payments.`,
    };
  }
  if (sectionId === SetupSectionId.SalesTax) {
    return {
      title: 'Sales Tax Requirements',
      description:
        'Mosey can help you manage state sales tax filing and payment requirements.',
      targetAudience: `Entities who sell tangible properties or taxable services are generally required to register with the state tax department to collect and remit sales tax.`,
      actionTitle: 'Review and configure sales tax requirements',
      actionDescription: `Import your sales tax account information and configure your sales tax filing and payment requirements.`,
    };
  }
  // TODO: copy for SetupSectionId.HRAndBenefits section id

  // Default overview copy
  return {
    title: 'Managing Your Configuration',
    description: `Mosey makes configuring your set up in states like ${regionName} by making it easy to identify the requirements you need to meet by answering a few simple questions.`,
    targetAudience: `Answer the question below in order to see what ${sectionId} requirements apply to your set up. Dont worry if you're not sure about the answer right now, you can skip it for now and come back to it later. In many cases, Mosey can automate some of these tasks time consuming tasks and complete these requirements for you.`,
    actionTitle: 'Answering Questions',
    actionDescription: `In order to activate your ${sectionId} module for the state of ${regionName}, you will have to answer questions relating to at least 1 out of 3 different requirements.`,
  };
};

type RequirementsSectionOverviewProps = {
  section: { nextSection: Section } & Section;
  regionName: string;
  firstRequirementOfSection: Requirement;
  triggerFindNextStep: () => void;
};

const RequirementsSectionOverview: FunctionComponent<
  RequirementsSectionOverviewProps
> = ({
  section,
  regionName,
  firstRequirementOfSection,
  triggerFindNextStep,
}) => {
  const [showDeferSectionPrompt, setShowDeferSectionPrompt] =
    useState<boolean>(false);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [scrollIntoViewRef] = useScrollIntoView(false);

  const overviewCopy = overviewCopyBySectionId(section.id, regionName);

  if (showDeferSectionPrompt) {
    return (
      <div className="no-scrollbar h-full flex-1 overflow-y-auto bg-white">
        <PromptMessage
          variant={PromptMessageTypeEnum.Defer}
          title="Not Your Flavor?"
          description={`We'll defer ${regionName} ${
            SetupSectionIdToName[section.id]
          } requirements to keep it out of your way. You can always come back if ${regionName} ${
            SetupSectionIdToName[section.id]
          } requirements becomes relevant to you in the future.`}
          requirementData={firstRequirementOfSection}
          forwardButtonLabel="Continue"
          onBack={() => setShowDeferSectionPrompt(false)}
          onForward={() => {
            setShowDeferSectionPrompt(false);
            triggerFindNextStep();
          }}
        />
      </div>
    );
  }

  return (
    <div
      className="no-scrollbar h-full flex-1 overflow-y-auto bg-white p-16"
      ref={scrollIntoViewRef}
    >
      <div className="flex flex-col">
        <h2 className="text-4xl font-black">{overviewCopy.title}</h2>

        <div className="pb-9 pt-4">
          <p>{overviewCopy.description}</p>

          <div className="mb-6 mt-9 flex items-center gap-x-2">
            <span className="relative shrink-0 text-lg font-medium">
              Who this is for
            </span>
            <span className="inline-block h-px w-full bg-gray-200" />
          </div>

          <p>{overviewCopy.targetAudience}</p>
        </div>

        <div className="flex items-center gap-x-8 rounded-lg bg-teal-350 p-9">
          <span className="flex size-48 shrink-0 items-center rounded-full bg-white p-6 text-sage-600">
            {createElement(SetupSectionIdToIcon[section.id])}
          </span>

          <div>
            <h3 className="text-lg font-bold">{overviewCopy.actionTitle}</h3>
            <p className="mb-6 mt-4">
              {overviewCopy.actionDescription} Let&apos;s start with the first
              requirement:{' '}
              <span className="font-bold">
                {firstRequirementOfSection.title}
              </span>
            </p>

            <div className="mt-6 flex gap-x-6">
              <Button
                size="xlarge"
                rightIcon={<ArrowRightIcon className="size-5" />}
                onClick={() => {
                  const queryParams = new URLSearchParams({
                    section: section.id,
                    requirement: firstRequirementOfSection.data_id,
                  });

                  navigate(`${pathname}?${queryParams.toString()}`, {
                    state: {
                      dismissIntroduction: true,
                    },
                  });
                }}
              >
                Continue
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

type LocationDetailSetupProps = {
  requirements: Requirement[];
  users: User[];
  sections: Section[];
  setupSectionId: SetupSectionId;
  requirementId: string | null;
  locationId: string;
  topElementRef: React.RefObject<HTMLDivElement | null>;
  navSetupComplete: boolean;
  toggleReloadView: () => void;
  triggerParentRefresh: () => void;
  legalEntityRegion: LegalEntityRegion;
  handleSetupComplete: () => void;
};

export const LocationDetailSetupNew: FunctionComponent<
  LocationDetailSetupProps
> = ({
  requirements,
  users,
  sections,
  setupSectionId,
  requirementId,
  locationId,
  topElementRef,
  navSetupComplete,
  toggleReloadView,
  triggerParentRefresh,
  legalEntityRegion,
  handleSetupComplete,
}) => {
  const revalidator = useRevalidator();
  const questionTasks = useAsyncValue() as TaskRef[];
  const {
    legal_entity: { notice_url: noticeUrl },
  } = useUser();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isDeferLoading, setIsDeferLoading] = useState<boolean>(false);
  const [isAssignmentLoading, setIsAssignmentLoading] =
    useState<boolean>(false);
  const [hasSeenSuccessPage, setHasSeenSuccessPage] = useState<boolean>(false);
  const [locationDetailState, dispatch] = useLocationDetailContext();
  const { state } = useLocation();

  const nextStepDictionary = useMemo(() => {
    const dictionary: NextStepDictionary = {};

    sections.map((section) =>
      section.requirements.forEach((reqId) => {
        const req = requirements.find((r) => r.data_id === reqId);

        if (req && req.schedule.category === 'one-time') {
          dictionary[reqId] = {
            requirement: req,
            section,
          };
        }
      }),
    );

    return dictionary;
  }, [requirements]);

  // Aggregate question tasks by requirement ID
  const questionTasksByRequirementId: { [key: string]: TaskQuestionRef[] } = {};

  for (let i = 0; i < questionTasks.length; i++) {
    const { source } = questionTasks[i];

    if (
      source.type === TaskType.question &&
      'related_requirement_ids' in source &&
      source.related_requirement_ids
    ) {
      for (let j = 0; j < source.related_requirement_ids.length; j++) {
        const reqId = source.related_requirement_ids[j];

        if (reqId in questionTasksByRequirementId) {
          questionTasksByRequirementId[reqId].push(source);
        } else {
          questionTasksByRequirementId[reqId] = [source];
        }
      }
    }
  }

  const setupQuestionsComplete =
    questionTasks &&
    questionTasks.filter(
      (qt) =>
        qt.source.type === TaskType.question &&
        'is_setup_related' in qt.source &&
        qt.source.is_setup_related,
    ).length == 0;

  const allQuestionsComplete = questionTasks && questionTasks.length == 0;

  const filteredSections = sections
    .map((section) => {
      return {
        ...section,
        requirements: section.requirements.filter(
          (reqId) => nextStepDictionary[reqId],
        ),
      };
    })
    .filter((section) => section.requirements.length);

  // Reset the component's internal state whenever the requirement and
  // location changes, otherwise you will have stale state when
  // switching between requirements
  useEffect(() => {
    setHasSeenSuccessPage(false);
    setIsDeferLoading(false);
    setIsAssignmentLoading(false);
    dispatch({ type: 'setIsImporting', payload: false });
  }, [locationId, requirementId]);

  const sectionInfo = filteredSections.find((s) => s.id === setupSectionId);

  if (!sectionInfo) {
    return <FatalError />;
  }

  // Default to the first requirement in the section
  const defaultReqId = sectionInfo.requirements[0];

  const findRequirement = (reqId: string | null): Requirement => {
    let req: Requirement;
    if (reqId) {
      const maybeReq = requirements.find((r) => r.data_id === reqId);
      if (!maybeReq) {
        throw Error(`Requirement not found ${reqId}`);
      }
      req = maybeReq;
    } else if (defaultReqId) {
      req = findRequirement(defaultReqId);
    } else {
      throw Error('No default requirement found');
    }
    return req;
  };

  const requirementData = findRequirement(requirementId);

  const isAttributeProducer = requirementData.produces.length > 0;
  const isManaged = requirementData.is_managed;
  const isManagedByMosey = requirementIsManagedByMosey(requirementData);
  const isDone = requirementIsDone(requirementData);
  const isImportable =
    !isDone && !isManaged && !isManagedByMosey && isAttributeProducer;
  const isNotImported =
    !isManaged &&
    !isManagedByMosey &&
    isAttributeProducer &&
    requirementData.produces.reduce((acc: boolean, curr): boolean => {
      const datum = legalEntityRegion.datums.find(
        ({ attribute: { id } }) => id === curr.attribute.id,
      );

      if (!datum || (datum && !datum.value)) {
        return true;
      }

      return acc;
    }, false);

  const isAutomatable = requirementData.is_automatable && !isManaged && !isDone;
  const isLocked = requirementData.is_blocked;
  const isAnyRemediationDone = requirementData.remediations.some(
    (remediation) => remediation.is_done,
  );
  const isNewRequirement =
    (requirementData.status === RequirementStatus.Todo ||
      requirementData.status === RequirementStatus.Skipped) &&
    !isAnyRemediationDone &&
    !isLocked;

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const sectionsDictionary = useMemo(() => {
    const dictionary: { [key: string]: { nextSection: Section } & Section } =
      {};

    filteredSections.forEach((section, index) => {
      dictionary[section.id] = {
        id: section.id,
        requirements: section.requirements,
        nextSection: filteredSections[index + 1],
      };
    });

    return dictionary;
  }, [requirements]);

  const currentSection = sectionsDictionary[setupSectionId];

  const areAllRequirementsTodo = currentSection.requirements.every(
    (req) =>
      nextStepDictionary[req].requirement.status === RequirementStatus.Todo,
  );

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const nextStepData = useMemo(() => {
    let foundCurrentId = false;

    for (let i = 0; i < filteredSections.length; i += 1) {
      const section = filteredSections[i];

      for (let j = 0; j < section.requirements.length; j += 1) {
        const next = nextStepDictionary[section.requirements[j]];
        const nextIsBlocked = next.requirement.is_blocked;

        if (!foundCurrentId) {
          if (requirementData.id === next.requirement.id) {
            foundCurrentId = true;
          }

          continue;
        }

        if (
          (next.requirement.status === RequirementStatus.Todo ||
            next.requirement.status === RequirementStatus.Skipped) &&
          !nextIsBlocked
        ) {
          return next;
        }
      }
    }

    return null;
  }, [requirementData]);

  const firstRequirementOfSection =
    nextStepDictionary[currentSection.requirements[0]]?.requirement;

  const handleSuccessRefresh = () => {
    setHasSeenSuccessPage(true);
    // Make sure the status updates to done
    toggleReloadView();
    // Make sure the registration info in the parent view updates
    triggerParentRefresh();
  };

  const handleRequirementChange = () => {
    toggleReloadView();
    triggerParentRefresh();
  };

  const handleAssignmentChange = (userId: string | null) => {
    setIsAssignmentLoading(true);

    const requirementCopy = { ...requirementData };

    let body: RequirementPatchUpdate;
    if (userId) {
      body = {
        operation: 'assign',
        // eslint-disable-next-line
        user_id: userId,
      };
      const user = users.find((u) => u.id === userId);
      requirementCopy.assigned_user = user;
    } else {
      body = {
        operation: 'unassign',
      };
      requirementCopy.assigned_user = undefined;
    }

    fetchApi({
      url: `/api/requirements/${requirementData.id}`,
      method: 'PATCH',
      body,
    }).then(() => {
      toggleReloadView();
      revalidator.revalidate();
    });

    // Add a timeout here so it never flashes on the screen
    setTimeout(() => setIsAssignmentLoading(false), 400);
  };

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const handleNotApplicable = useCallback(
    async (e?: MouseEvent) => {
      if (e) {
        e.preventDefault();
      }

      setIsDeferLoading(true);

      async function updateRequirement() {
        const status =
          requirementData.status === RequirementStatus.Deferred
            ? RequirementStatus.Todo
            : RequirementStatus.Deferred;

        await fetchApi({
          url: `/api/requirements/${requirementData.id}`,
          method: 'PUT',
          body: { status },
        });

        revalidator.revalidate();
        // Add a timeout here so it never flashes on the screen
        setTimeout(() => setIsDeferLoading(false), 400);
      }

      return updateRequirement();
    },
    [requirementData],
  );

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const handleFindNextStep = useCallback(() => {
    searchParams.delete('section');
    searchParams.delete('requirement');
    setSearchParams(searchParams);
    toggleReloadView();
    triggerParentRefresh();
  }, [searchParams, toggleReloadView, triggerParentRefresh, setSearchParams]);

  const selectedSectionRequirements: Requirement[] = [];
  const visitedDataIds = new Set();
  for (const r of requirements) {
    if (!sectionInfo.requirements.includes(r.data_id)) {
      continue;
    }

    // Ignore requirements that have a data ID we've already visited
    if (visitedDataIds.has(r.data_id)) {
      continue;
    }

    visitedDataIds.add(r.data_id);
    selectedSectionRequirements.push(r);
  }

  const selectedSectionPercentComplete =
    (selectedSectionRequirements.filter(
      (requirement) =>
        requirementIsDone(requirement) ||
        requirement.is_blocked ||
        requirementIsSkipped(requirement) ||
        requirementIsDeferred(requirement),
    ).length /
      selectedSectionRequirements.length) *
    100;

  const selectedSectionIsComplete = selectedSectionPercentComplete === 100;

  const isFirstRequirement =
    firstRequirementOfSection.data_id === requirementData.data_id;

  const shouldRenderOnboardingScreen =
    !state?.dismissIntroduction &&
    !isAnyRemediationDone &&
    isNewRequirement &&
    isFirstRequirement &&
    areAllRequirementsTodo;

  const shouldRenderSetup =
    (isNewRequirement && !shouldRenderOnboardingScreen) ||
    hasSeenSuccessPage ||
    (locationDetailState.isImporting && !shouldRenderOnboardingScreen);

  const shouldRenderSummary =
    !isNewRequirement && !hasSeenSuccessPage && !shouldRenderSetup;

  const hasUnsatisfiedCriteria =
    requirementData.has_criteria && !requirementData.is_criteria_satisfied;

  if (
    !legalEntityRegion.is_setup_complete &&
    !setupQuestionsComplete &&
    requirements.every((r) => r.status === 'todo')
  ) {
    return (
      <LocationDetailViewAssessmentInterstitial
        resolverUrl={`/locations/usa/${legalEntityRegion.region.code.toLowerCase()}/resolver/assessment?skip=true`}
      />
    );
  }

  return (
    <>
      <QuestionTasksBanner
        regionCode={locationId}
        isNested={false}
        show={
          !setupQuestionsComplete || (navSetupComplete && !allQuestionsComplete)
        }
        isSetup={!setupQuestionsComplete}
      />
      <div className="flex h-full p-6">
        <div
          className="flex w-96 shrink-0 flex-col border"
          data-testid="navigator-container"
        >
          <div className="flex-1 border-b bg-white px-6 py-4 text-lg font-bold text-zinc-700">
            Navigator
          </div>
          <div className="h-full overflow-y-auto bg-white">
            <div>
              <SetupNavigatorNew
                sections={filteredSections}
                setupSectionId={setupSectionId}
                requirementId={
                  areAllRequirementsTodo && !state?.dismissIntroduction
                    ? null
                    : requirementData.data_id
                }
                requirements={requirements}
                nextStepDictionary={nextStepDictionary}
                hasAllItemsBlocked={shouldRenderOnboardingScreen}
                hasDismissedIntroductionScreen={state?.dismissIntroduction}
              />
            </div>
          </div>
        </div>
        <div
          className="flex h-full flex-1 flex-col"
          data-testid="location-detail-setup-detail-pane"
        >
          {!isNewRequirement && (
            <div className="flex flex-1 flex-wrap items-center border-r border-t bg-white px-6 py-4">
              <div className="flex flex-1 items-center justify-between gap-x-4">
                <div className="flex">
                  <div
                    className={clsx('size-6 fill-current', {
                      'text-lime-500': selectedSectionIsComplete,
                      'text-rose-700': !selectedSectionIsComplete,
                    })}
                  >
                    {createElement(SetupSectionIdToIcon[setupSectionId])}
                  </div>
                  <h2 className="ml-2 flex-1 text-lg font-bold text-zinc-700">
                    {SetupSectionIdToName[setupSectionId]}
                  </h2>
                </div>
                <SectionProgress progress={selectedSectionPercentComplete} />
              </div>
            </div>
          )}
          <div className="flex h-full items-start overflow-y-auto border-y border-r">
            {shouldRenderOnboardingScreen && (
              <RequirementsSectionOverview
                regionName={legalEntityRegion.region.name}
                section={currentSection}
                firstRequirementOfSection={firstRequirementOfSection}
                triggerFindNextStep={handleFindNextStep}
              />
            )}

            {shouldRenderSetup && (
              <div className="no-scrollbar h-full flex-1 overflow-y-auto bg-white">
                <RequirementSetup
                  nextStepDictionary={nextStepDictionary}
                  users={users}
                  topElementRef={topElementRef}
                  handleSuccessRefresh={handleSuccessRefresh}
                  legalEntityRegion={legalEntityRegion}
                  isAutomatable={isAutomatable}
                  isImportable={isImportable}
                  locationId={locationId}
                  requirementData={requirementData}
                  setHasSeenSuccessPage={setHasSeenSuccessPage}
                  handleNotApplicable={handleNotApplicable}
                  nextStepData={nextStepData}
                  handleSetupComplete={handleSetupComplete}
                />
              </div>
            )}

            {shouldRenderSummary && (
              <div className="no-scrollbar flex h-full flex-1 flex-col overflow-y-auto bg-white pb-6">
                <div className="grid pt-10 2xl:grid-cols-12">
                  <div className="col-span-12 2xl:flex">
                    <div className="flex items-start lg:col-span-11 2xl:col-span-7">
                      <div className="px-9">
                        <div className="flex items-start justify-between">
                          <h3 className="text-2xl font-bold tracking-tight text-zinc-700">
                            {requirementData.title}
                          </h3>
                          <div className="flex gap-x-4">
                            {(!isManaged || !isDone) && (
                              <div className="col-span-1 grid justify-items-end">
                                <div className="ml-1 mt-1">
                                  {isDeferLoading ? (
                                    <div className="flex items-center">
                                      <span className="mr-2 text-sm text-gray-600">
                                        Saving
                                      </span>
                                      <LoadingIcon className="size-6 cursor-pointer text-gray-400" />
                                    </div>
                                  ) : (
                                    <DropdownMenu
                                      isIconButton
                                      ariaButtonText="Change requirement status"
                                      buttonContent={
                                        <MoreIcon
                                          className={iconStyle}
                                          aria-hidden="true"
                                        />
                                      }
                                    >
                                      <MenuItem
                                        as="button"
                                        onClick={async () => {
                                          await handleNotApplicable();
                                          triggerParentRefresh();
                                          // only toggle reload if we're in summary view
                                          toggleReloadView();
                                        }}
                                      >
                                        {requirementData.status ===
                                        RequirementStatus.Deferred
                                          ? 'Mark as Todo'
                                          : 'Defer'}
                                      </MenuItem>
                                    </DropdownMenu>
                                  )}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="mt-2 text-sm text-zinc-700 xl:grid xl:grid-cols-12">
                          <div className="xl:col-span-8">
                            <RequirementContentHeader
                              description={requirementData.description}
                              resources={requirementData.resources}
                            />

                            {noticeUrl &&
                              requirementData?.tags?.includes(
                                AutomationTypeEnum.NoticeBoard,
                              ) &&
                              isManagedByMosey &&
                              isDone && (
                                <div className="mt-4 space-y-4">
                                  <p>
                                    Copy the link below to an internal wiki or
                                    document that is readibly accessible to all
                                    remote employees and notify them of where to
                                    find it. Updates will happen automatically
                                    for all regions you add.
                                  </p>

                                  <NoticeUrlDisplay
                                    region={legalEntityRegion.region}
                                  />
                                </div>
                              )}
                          </div>

                          {isNotImported &&
                            !hasUnsatisfiedCriteria &&
                            !requirementData.is_blocked && (
                              <div className="mt-4 flex items-start xl:col-span-4 xl:mt-0 xl:justify-end xl:pl-4">
                                <div className="rounded border bg-sage-200 p-4 text-base leading-6 text-rose-600 xl:max-w-xs">
                                  <div className="flex items-center justify-between">
                                    <SparkleIcon className="mr-2 mt-2 size-5 self-start rounded-3xl text-rose-700" />
                                    <div className="flex-1">
                                      <h4 className="text-base font-bold leading-7 text-zinc-700">
                                        Already did this?
                                      </h4>
                                      <div className="text-sm text-zinc-700">
                                        Import your information to mark this
                                        requirement as done and set up tracking.
                                      </div>
                                    </div>
                                  </div>
                                  <Button
                                    type="button"
                                    className="ml-6 mt-2"
                                    variant="secondary"
                                    onClick={() =>
                                      dispatch({
                                        type: 'setIsImporting',
                                        payload: true,
                                      })
                                    }
                                  >
                                    Import
                                  </Button>
                                </div>
                              </div>
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-8 border-y py-3 text-sm">
                  <div className="flex flex-wrap items-center gap-y-4 px-8">
                    {requirementData.due_date && (
                      <>
                        <span className="font-semibold text-zinc-800">
                          Due Date
                        </span>
                        <span className="ml-2 mr-4 inline-block border-r pr-4">
                          {formatDateFromString(requirementData.due_date)}
                        </span>
                      </>
                    )}
                    <span className="font-semibold text-zinc-800">Status</span>
                    <span className="ml-2 mr-4 inline-block border-r pr-4">
                      <Pill
                        Icon={
                          statusIconMappingNew[
                            requirementComputedStatus(requirementData)
                          ]
                        }
                        size="small"
                        variant={
                          statusColorMapping[
                            requirementComputedStatus(requirementData)
                          ]
                        }
                      >
                        {requirementComputedStatus(requirementData)}
                      </Pill>
                    </span>
                    <span className="inline font-semibold text-zinc-800">
                      Assigned to
                    </span>
                    <span className="ml-2">
                      <LoadingIcon
                        className={clsx(
                          'mr-1 inline size-5 cursor-pointer text-gray-400',
                          {
                            hidden: !isAssignmentLoading,
                          },
                        )}
                      />
                      <AssignSelect
                        users={users}
                        assignedTo={requirementData.assigned_user}
                        onChange={handleAssignmentChange}
                        alignRight
                      />
                    </span>
                  </div>
                </div>
                <div className="mt-7 flex grow flex-col px-8">
                  <RequirementProgressNew
                    requirement={requirementData}
                    questionTasks={
                      questionTasksByRequirementId[requirementData.data_id]
                    }
                    isManaged={isManaged}
                    isManagedByMosey={isManagedByMosey}
                    onRequirementChange={handleRequirementChange}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

type LocationDetailSetupViewProps = {
  legalEntityRegion: LegalEntityRegion;
  requirements: Requirement[];
  topElementRef: React.RefObject<HTMLDivElement | null>;
  navSetupComplete: boolean;
  triggerParentRefresh: () => void;
  handleSetupComplete: () => void;
};

const buildNavigatorSections = (
  regionNavigator: RegionNavigator,
  requirements: Requirement[],
  taxClass: TaxClassEnum,
  hasUniqueNavigator: boolean,
  locationId: string,
  incorporationRegionCode: string = '',
) => {
  const sections: Section[] = [];
  const isHomeState =
    locationId.toLowerCase() === incorporationRegionCode.toLowerCase();

  const filterRequirements = (reqDataIds: string[]) => {
    // return all requirements when we don't know if location is foreign/home
    if (incorporationRegionCode === '') {
      return reqDataIds;
    } else {
      const tag: AutomationTypeEnum = isHomeState
        ? AutomationTypeEnum.ForeignStateOnly
        : AutomationTypeEnum.HomeStateOnly;

      const filteredReqIds = reqDataIds.filter((reqId) => {
        const req = requirements.find((req) => req.data_id === reqId);
        return !req?.tags?.includes(tag);
      });
      return filteredReqIds;
    }
  };

  if (regionNavigator.payroll_requirements.length) {
    const filteredPayrollRequirements = filterRequirements(
      regionNavigator.payroll_requirements,
    );
    sections.push({
      id: SetupSectionId.Payroll,
      requirements: filteredPayrollRequirements,
    });
  }
  if (regionNavigator.hr_requirements.length) {
    const filteredHRRequirements = filterRequirements(
      regionNavigator.hr_requirements,
    );
    sections.push({
      id: SetupSectionId.HR,
      requirements: filteredHRRequirements,
    });
  }
  if (regionNavigator.hr_benefits_requirements.length) {
    const filteredHRBenefitsRequirements = filterRequirements(
      regionNavigator.hr_benefits_requirements,
    );
    sections.push({
      id: SetupSectionId.HRAndBenefits,
      requirements: filteredHRBenefitsRequirements,
    });
  }
  // TODO: remove check once all S Corp navigators are configured
  if (hasUniqueNavigator || taxClass !== TaxClassEnum.s_corp) {
    if (regionNavigator.registration_requirements.length) {
      const filteredRegistrationRequirements = filterRequirements(
        regionNavigator.registration_requirements,
      );
      sections.push({
        id: SetupSectionId.Registration,
        requirements: filteredRegistrationRequirements,
      });
    }
    if (regionNavigator.entity_tax_requirements.length) {
      const filteredEntityTaxRequirements = filterRequirements(
        regionNavigator.entity_tax_requirements,
      );
      sections.push({
        id: SetupSectionId.CorpTax,
        requirements: filteredEntityTaxRequirements,
      });
    }
  }
  if (regionNavigator.sales_tax_requirements.length) {
    const filteredSalesTaxRequirements = filterRequirements(
      regionNavigator.sales_tax_requirements,
    );
    sections.push({
      id: SetupSectionId.SalesTax,
      requirements: filteredSalesTaxRequirements,
    });
  }
  return sections;
};

export const LocationDetailSetupViewNew: FunctionComponent<
  LocationDetailSetupViewProps
> = ({
  legalEntityRegion,
  requirements,
  triggerParentRefresh,
  topElementRef,
  navSetupComplete,
  handleSetupComplete,
}) => {
  const { questionTasksTodo } = useRouteLoaderData('question-tasks-jm') as {
    questionTasksTodo: Promise<TaskRef[]>;
  };
  const [searchParams, setSearchParams] = useSearchParams();
  const locationId = legalEntityRegion.region.code.toLowerCase();
  const { tax_class: taxClass, incorporation_region: incorporationRegion } =
    useUser().legal_entity;

  const sections = buildNavigatorSections(
    legalEntityRegion.region_navigator,
    requirements,
    taxClass,
    legalEntityRegion.has_unique_navigator,
    locationId,
    incorporationRegion?.code,
  );

  if (sections.length === 0) {
    throw Error(`Unsupported location with location id: ${locationId}`);
  }

  let setupSectionId = searchParams.get('section') as SetupSectionId;
  let requirementId = searchParams.get('requirement');
  let shouldUpdateSearchParams = false;

  let section: Section | null = null;

  if (setupSectionId) {
    section = findSectionFromSetupSectionId(sections, setupSectionId);

    if (!section) {
      shouldUpdateSearchParams = true;
      searchParams.delete('section');
    }
  }

  if (requirementId) {
    const requirementSection = findSectionFromRequirementId(
      sections,
      requirementId,
    );

    if (requirementSection) {
      /**
       * This is a special case where both search parameters were provided but
       * they correspond to two different sections. In this case, use the section
       * associated with the requirement, and update the URL search parameters.
       */
      if (setupSectionId && requirementSection !== section) {
        shouldUpdateSearchParams = true;
        searchParams.set('section', requirementSection.id);
      }

      section = requirementSection;
      setupSectionId = section.id;
    } else {
      shouldUpdateSearchParams = true;
      searchParams.delete('requirement');
    }
  }

  useEffect(() => {
    if (shouldUpdateSearchParams) {
      setSearchParams(searchParams, { replace: true });
    }
  }, [shouldUpdateSearchParams]);

  const endDate = new Date();
  endDate.setDate(endDate.getDate() + 90);

  // Sentinel so the view can be reloaded if requirements change
  const [shouldRefreshData, toggleShouldRefreshData] = useState<boolean>(false);
  const batchResponse = useBatchApi(
    [
      {
        url: '/api/users',
        method: 'GET',
      },
    ],
    [setupSectionId, shouldRefreshData],
  );

  const componentPropsFn = ([
    usersResponse,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ]: any[]): LocationDetailSetupProps => {
    /**
     * If we are not given a requirement ID via query parameters, we can only
     * determine which requirement needs attention first using requirements data
     * response from the API.
     */
    if (!requirementId && setupSectionId) {
      requirementId = findNextRequirementFromSection(section, requirements);
    } else if (!requirementId && !setupSectionId) {
      [setupSectionId, requirementId] = findNextSectionIdAndRequirementId(
        sections,
        requirements,
      );
    }

    /**
     * Default to the first section if section ID was not provided as a query
     * parameter, or could not be determined via the requirement ID.
     */
    if (setupSectionId === null) {
      setupSectionId = sections[0].id;
    }

    return {
      requirements: requirements,
      users: usersResponse,
      toggleReloadView: () => toggleShouldRefreshData((prev) => !prev),
      sections,
      setupSectionId,
      requirementId,
      locationId,
      triggerParentRefresh,
      topElementRef,
      navSetupComplete,
      legalEntityRegion,
      handleSetupComplete,
    };
  };

  return (
    <Suspense fallback={<Loading />}>
      <Await resolve={questionTasksTodo}>
        <BatchApiStatusHandler
          batchResponse={batchResponse}
          component={LocationDetailSetupNew}
          componentProps={componentPropsFn}
        />
      </Await>
    </Suspense>
  );
};
