/**
 * Provides routes in the applications and boostraps the app.
 *
 * The router is separated into two parts: unauthenticated routes and
 * authenticated routes. Unauthenticated routes should only be used for
 * authentication and signup. Authenticated routes are wrapped by the
 * <AuthenticatedRoutes /> component which checks the user's authentication on
 * every route change.
 *
 * The app is bootstrapped by the <AppChrome /> for authenticated users only. It
 * performs an API call needed for the apps global context – data that is
 * available to every view in the application. There is a layout route that is a
 * child route of <AppChrome />, <Nav /> that renders the "chrome" (navigation,
 * header, etc.). Plus, certain routes are gated using the components
 * <GateActiveSubscription />, <GateActiveUser />, <GateBusinessProfile />,
 * and <GateBasicPlanSetup />.
 */

import {
  Route,
  Navigate,
  useLocation,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  Outlet,
} from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { isAuthenticated } from '@mosey/utils/auth';

import { Nav } from './components/base/Nav';
import { UpcomingLegislationView } from './views/UpcomingLegislation';
import TestHostedSignup from './views/demo/TestHostedSignup';
import { PlatformAuth } from './views/PlatformAuth';
import { FormPreviewView } from './views/FormPreview';
import { CallbackInterstitial } from './views/CallbackInterstitial';
import { SingleSignOn } from './views/SingleSignOn';
import { SingleSignOnCallback } from './views/SingleSignOnCallback';
import { IS_EMBEDDED } from './settings/config';
import { PlatformAccountLoginView } from './views/platform/PlatformAccountLoginView';
import { BusinessInformationFormView } from './views/BusinessInformationForm';
import { BusinessEditView } from './views/officers_and_owners/BusinessEdit';
import { ConditionalMailroomSettingsView } from './views/settings/MailroomSettingsView';
import { RipplingReportView } from './views/onboarding/RipplingReportView';
import * as AppChrome from './views/AppChrome';
import { BusinessInformationView } from './views/BusinessInformation';
import { NoticeEnrollmentView, NoticesView } from './views/Notices';
import { Login } from './views/Login';
import * as SignUpView from './views/SignUp';
import Logout from './views/Logout';
import * as GustoLoaders from './views/GustoAuthorization';
import PasswordResetRequest from './views/PasswordResetRequest';
import PasswordResetRedemption from './views/PasswordResetRedemption';
import {
  ComplianceCheck,
  ComplianceCheckAbout,
  ComplianceCheckDefaultView,
  ComplianceCheckScanResultsView,
} from './views/ComplianceCheck';
import {
  ActivateSubscriptionView,
  CheckoutView,
  PlanSelectionView,
} from './views/Subscriptions';
import * as VerifyEmail from './views/VerifyEmail';
import { ConditionalOnboardingVerifyEmail } from './views/OnboardingVerifyEmail';
import { ConditionalBasicPlanSetupView } from './views/BasicPlanSetup';
import { GateActiveSubscription } from './views/GateActiveSubscription';
import { GateActiveUser } from './views/GateActiveUser';
import GateBusinessProfile from './views/GateBusinessProfile';
import GateBasicPlanSetup from './views/GateBasicPlanSetup';
import * as SearchView from './views/Search';
import * as TasksOverview from './views/tasks-framework/overview/TasksOverview';
import * as TasksView from './views/Tasks';
import { LocationsView } from './views/Locations';
import * as UsSetupView from './views/UsSetup';
import {
  LocationViewGate,
  loader as LocationViewLoader,
} from './views/LocationDetailView';
import { MailRoomView } from './views/MailRoomView';
import { DocumentsView } from './views/DocumentsView';
import { ComplianceCalendarView } from './views/ComplianceCalendar';
import { RequirementView } from './views/Requirement';
import { InviteView } from './views/Invite';
import { FinchConnectView } from './views/FinchConnect';
import { SettingsView } from './views/Settings';

import { IntegrationsView } from './views/Integrations';
import {
  OfficersAndOwnersCreateView,
  PeopleEditView,
} from './views/officers_and_owners';
import * as OfficersAndOwners from './views/officers_and_owners/OfficersAndOwners';
import Onboarding, { PayrollSetup } from './views/Onboarding';
import { AutomationView } from './views/AutomationView';
import LocalPayrollSetupView from './views/LocalPayrollSetup';
import SalesTaxSetupView from './views/SalesTaxSetup';
import { AutomationWrapper } from './views/AutomationWrapper';
import { NotFound } from './views/NotFound';
import { MailUploadView } from './views/MailUpload';
import * as Resolver from './views/tasks-framework/resolver/Resolver';
import * as Task from './views/tasks-framework/tasks/Task';
import { ResolverIntro } from './views/tasks-framework/resolver/Intro';
import * as ResolverComplete from './views/tasks-framework/resolver/Complete';
import * as TaskAutomation from './views/tasks-framework/tasks/TaskAutomation';
import { ErrorBoundary } from './views/ErrorBoundary';
import { Loading } from './views/Loading';
import * as AccountView from './views/account';
import * as PreferencesView from './views/preferences';
import { userLoader } from './utils/loaders';
import { Welcome } from './views/handbook/setup/Welcome';
import { SetupForm } from './views/handbook/setup/SetupForm';
import { Overview } from './views/handbook/Overview';
import { RipplingWorkflowView } from './views/onboarding/RipplingWorkflowView';
import { PolicySectionDetailView } from './views/handbook/section/DetailView';
import {
  handbookLoader,
  policySectionFormsLoader,
  shouldPolicySectionFormsRevalidate,
} from './views/handbook/section/loaders';
import { PolicySectionConfigureWelcome } from './views/handbook/section/configure/Welcome';
import { PolicySectionConfigureForm } from './views/handbook/section/configure/ConfigureForm';
import { Publish } from './views/handbook/Publish';
import { Signatures } from './views/handbook/Signatures';
import { CustomCategoryPolicy } from './views/handbook/section/CustomCategoryPolicy';
import { CustomPolicy } from './views/handbook/section/CustomPolicy';
import { AddSection } from './views/handbook/section/AddSection';
import { RequestHandbookAccess } from './views/handbook/RequestHandbookAccess';

/* Wraps routes in an authentication check. This checks on each route
change so there is no need to wrap individual routes in authentication
checks */
const AuthenticatedRoutes = () => {
  const location = useLocation();

  if (isAuthenticated()) {
    return <Outlet />;
  }

  const redirectUrl = encodeURIComponent(
    `${location.pathname}${location.search}${location.hash}`,
  );

  return (
    <Navigate
      to={`/login${redirectUrl !== '%2F' ? `?redirect=${redirectUrl}` : ''}`}
      replace
    />
  );
};

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const embeddedSharedWithAppRoutes = (
  <>
    <Route path="/notices/summary/:publicKey" element={<NoticesView />} />
  </>
);

const embeddedOnlyRoutes = (
  <>
    <Route path="/compliance-check" element={<ComplianceCheck />}>
      <Route index element={<ComplianceCheckDefaultView />} />
      <Route
        path="about"
        element={<ComplianceCheckAbout />}
        action={ComplianceCheckAbout.action}
      />
      <Route
        path="scan/results"
        loader={ComplianceCheckScanResultsView.loader}
        element={<ComplianceCheckScanResultsView source="stripe" />}
      />
    </Route>
  </>
);
export let routes = (
  <Route element={<Outlet />} errorElement={<ErrorBoundary />}>
    {embeddedSharedWithAppRoutes}
    <Route path="/test-hosted-signup" element={<TestHostedSignup />} />
    <Route path="/login/sso_callback" element={<SingleSignOnCallback />} />
    <Route path="/login/sso" element={<SingleSignOn />} />
    <Route path="/login" element={<Login />} />
    <Route
      path="/signup"
      loader={SignUpView.loader}
      action={SignUpView.action}
      element={<SignUpView.Component />}
    />
    <Route path="/logout" element={<Logout />} />
    <Route path="/platform">
      <Route index element={<PlatformAuth />} />
      <Route
        path="payroll/authorize"
        element={<Loading />}
        loader={GustoLoaders.beginAuthorizationLoader}
      />
      <Route
        path="payroll/onboard"
        element={<Loading />}
        loader={GustoLoaders.onboardingLoader}
      />
      <Route
        path="payroll/oauth/gusto"
        element={<Loading />}
        loader={GustoLoaders.validateAuthorizationLoader}
      />
    </Route>
    <Route path="/password-reset" element={<PasswordResetRequest />} />
    <Route
      path="/password-reset-redeem"
      element={<PasswordResetRedemption />}
    />
    <Route path="/compliance-check" element={<ComplianceCheck />}>
      <Route index element={<ComplianceCheckDefaultView />} />
      <Route
        path="about"
        element={<ComplianceCheckAbout />}
        action={ComplianceCheckAbout.action}
      />
      <Route
        path="scan/results"
        loader={ComplianceCheckScanResultsView.loader}
        element={<ComplianceCheckScanResultsView />}
      />
    </Route>
    <Route
      path="/logins/:loginEntryToken"
      element={<PlatformAccountLoginView />}
    />
    <Route path="mail-resolver/:token" element={<MailUploadView />} />
    <Route element={<AuthenticatedRoutes />}>
      <Route path="subscription/checkout/:planId" element={<CheckoutView />} />
      <Route path="subscription/pricing" element={<PlanSelectionView />} />
      <Route
        path="subscription/activate/:planId"
        element={<ActivateSubscriptionView />}
      />
      <Route
        element={<Outlet />}
        id="user"
        loader={userLoader}
        shouldRevalidate={({
          currentUrl,
          nextUrl,
          defaultShouldRevalidate,
        }) => {
          /**
           * Don't refetch if we're on location setup routes and we're only
           * changing the search parameters. But refresh if the user is
           * switching legal entities.
           *
           * TODO: [IMPORTANT!] Remove this check once Location v3 is live!
           */
          if (
            currentUrl.pathname.startsWith('/locations/usa/') &&
            nextUrl.pathname.startsWith('/locations/usa/') &&
            currentUrl.pathname === nextUrl.pathname &&
            !nextUrl.searchParams.has('legal_entity_id')
          ) {
            return false;
          }

          return defaultShouldRevalidate;
        }}
      >
        <Route element={<AppChrome.Component />}>
          <Route
            element={
              <Nav>
                <Outlet />
              </Nav>
            }
          >
            <Route
              path="verify/email"
              loader={VerifyEmail.loader}
              element={<VerifyEmail.Component />}
            />
            <Route
              path="onboarding/verify-email"
              element={<ConditionalOnboardingVerifyEmail />}
            />
            <Route
              path="onboarding/business-information"
              element={<BusinessInformationFormView />}
            />
            <Route
              path="onboarding/basic"
              element={<ConditionalBasicPlanSetupView />}
            />
            <Route path="success-callback" element={<CallbackInterstitial />} />
            <Route
              element={
                <GateActiveSubscription>
                  <GateActiveUser>
                    <GateBusinessProfile>
                      <GateBasicPlanSetup>
                        <Outlet />
                      </GateBasicPlanSetup>
                    </GateBusinessProfile>
                  </GateActiveUser>
                </GateActiveSubscription>
              }
            >
              <Route index element={<Navigate to="/home" replace />} />
              <Route
                path="home"
                id="tasks-overview/home"
                loader={TasksOverview.loader}
                element={<TasksOverview.Component />}
              />
              <Route
                path="search"
                loader={SearchView.loader}
                element={<SearchView.Component />}
              />
              <Route path="locations/:countryId">
                <Route index element={<LocationsView />} />
                <Route
                  path="setup"
                  loader={UsSetupView.loader}
                  action={UsSetupView.action}
                  element={<UsSetupView.Component />}
                />
                <Route
                  path=":locationId/*"
                  id="question-tasks-jm"
                  loader={LocationViewLoader}
                  element={<LocationViewGate />}
                >
                  <Route
                    path="resolver/:resolverType?"
                    id="resolver-tasks/locations"
                    loader={Resolver.loader}
                    element={<Resolver.Component />}
                  >
                    <Route index element={<ResolverIntro />} />
                    <Route
                      path="tasks/:taskId"
                      id="task/locations"
                      loader={Task.loader}
                      action={Task.action}
                      element={<Task.Component />}
                    >
                      <Route
                        path="automation"
                        loader={TaskAutomation.loader}
                        element={<TaskAutomation.Component />}
                      />
                    </Route>
                    <Route
                      path="complete"
                      loader={ResolverComplete.loader}
                      element={<ResolverComplete.Component />}
                    />
                  </Route>
                </Route>
              </Route>
              <Route
                path="resolver/:resolverType?"
                id="resolver-tasks/recurring"
                loader={Resolver.loader}
                element={<Resolver.Component />}
              >
                <Route index element={<ResolverIntro />} />
                <Route
                  path="tasks/:taskId"
                  id="task/recurring"
                  loader={Task.loader}
                  action={Task.action}
                  element={<Task.Component />}
                >
                  <Route
                    path="automation"
                    loader={TaskAutomation.loader}
                    element={<TaskAutomation.Component />}
                  />
                </Route>
                <Route
                  path="complete"
                  loader={ResolverComplete.loader}
                  element={<ResolverComplete.Component />}
                />
              </Route>
              <Route path="legislation" element={<UpcomingLegislationView />} />
              <Route path="mail" element={<MailRoomView />} />
              <Route path="handbook" element={<Outlet />}>
                <Route
                  path="request"
                  element={<RequestHandbookAccess />}
                  action={RequestHandbookAccess.action}
                />
                <Route path="setup" element={<Outlet />}>
                  <Route index element={<Navigate to="welcome" replace />} />
                  <Route path="welcome" element={<Welcome />} />
                  <Route
                    path="form"
                    element={<SetupForm />}
                    loader={SetupForm.loader}
                  />
                </Route>
                <Route
                  element={<Outlet />}
                  id="handbook"
                  loader={handbookLoader}
                >
                  <Route index element={<Navigate to="overview" replace />} />
                  <Route
                    path="publish"
                    element={<Publish />}
                    action={Publish.action}
                  />
                  <Route
                    path="overview"
                    element={<Overview />}
                    action={Overview.action}
                  />
                  <Route
                    path="signatures"
                    element={<Signatures />}
                    loader={Signatures.loader}
                    id="signatures"
                  />
                  <Route path="create-section" element={<AddSection />} />
                  <Route
                    path="section/:sectionId"
                    id="policy-section"
                    element={<Outlet />}
                    loader={policySectionFormsLoader}
                    shouldRevalidate={shouldPolicySectionFormsRevalidate}
                  >
                    <Route index element={<Navigate to="policies" replace />} />
                    <Route path="configure" element={<Outlet />}>
                      <Route
                        index
                        element={<Navigate to="welcome" replace />}
                      />
                      <Route
                        path="welcome"
                        element={<PolicySectionConfigureWelcome />}
                      />
                      <Route
                        path=":policySlug"
                        element={<PolicySectionConfigureForm />}
                        action={PolicySectionConfigureForm.action}
                      />
                    </Route>
                    <Route path="policies">
                      <Route index element={<Navigate to="list" replace />} />
                      <Route
                        id="policy-section-details"
                        path="list"
                        element={<PolicySectionDetailView />}
                        loader={PolicySectionDetailView.loader}
                        action={PolicySectionDetailView.action}
                      />
                      <Route
                        path="create"
                        element={<CustomPolicy />}
                        action={CustomPolicy.action}
                      />
                      <Route
                        path=":policySlug"
                        element={<CustomPolicy />}
                        action={CustomPolicy.action}
                      />
                    </Route>

                    <Route
                      path="categories/:categoryId"
                      id="custom-category-policy"
                      element={<CustomCategoryPolicy />}
                      action={CustomCategoryPolicy.action}
                      loader={CustomCategoryPolicy.loader}
                    />
                  </Route>
                </Route>
              </Route>
              <Route path="documents/:requestId?" element={<DocumentsView />} />
              <Route path="tasks">
                <Route index element={<Navigate to="me" replace />} />
                <Route
                  path="me"
                  loader={TasksView.loader}
                  element={<TasksView.Component />}
                />
                <Route
                  path="all"
                  loader={TasksView.loader}
                  element={<TasksView.Component />}
                />
                <Route
                  path=":taskId"
                  id="task/standalone"
                  loader={Task.loader}
                  action={Task.action}
                  element={<Task.Component />}
                >
                  <Route
                    path="automation"
                    loader={TaskAutomation.loader}
                    element={<TaskAutomation.Component />}
                  />
                </Route>
              </Route>
              <Route path="calendar" element={<ComplianceCalendarView />} />
              <Route
                path="requirement/:requirementId/:dueDate?"
                element={<RequirementView />}
              />
              <Route path="settings/invite" element={<InviteView />} />
              <Route
                path="settings/oauth/payroll/result"
                element={<FinchConnectView />}
              />
              <Route path="settings" element={<SettingsView />}>
                <Route index element={<Navigate to="account" replace />} />
                <Route
                  path="account"
                  loader={AccountView.loader}
                  element={<AccountView.Component />}
                />
                <Route
                  path="preferences"
                  loader={PreferencesView.loader}
                  action={PreferencesView.action}
                  element={<PreferencesView.Component />}
                />
                <Route
                  path="business-information"
                  element={<BusinessInformationView />}
                />
                <Route
                  path="integrations"
                  element={<IntegrationsView />}
                  action={IntegrationsView.action}
                  loader={IntegrationsView.loader}
                />
                <Route
                  path="mailroom"
                  element={<ConditionalMailroomSettingsView />}
                />
                <Route
                  path="officers-and-owners"
                  loader={OfficersAndOwners.loader}
                  action={OfficersAndOwners.action}
                  element={<OfficersAndOwners.Component />}
                />
                <Route
                  path="officers-and-owners/create"
                  element={<OfficersAndOwnersCreateView />}
                />
                <Route
                  path="people/edit/:personId"
                  element={<PeopleEditView />}
                />
                <Route
                  path="businesses/edit/:businessId"
                  element={<BusinessEditView />}
                />
              </Route>
              <Route path="onboarding" element={<Onboarding />}>
                <Route index element={<Navigate to="setup" replace />} />
                <Route path="setup" element={<PayrollSetup />} />
                <Route path="complete" element={<FinchConnectView />} />
                <Route path="rippling" element={<Outlet />}>
                  <Route index element={<Navigate to="report" replace />} />
                  <Route path="report" element={<RipplingReportView />} />
                  <Route path="workflow" element={<RipplingWorkflowView />} />
                </Route>
              </Route>
              <Route
                path="notices/enrollment/:locationId/:requirementId"
                element={<NoticeEnrollmentView />}
              />
              <Route
                path="local-payroll-setup/:locationId/:requirementId"
                element={<LocalPayrollSetupView />}
              />
              <Route
                path="sales-tax-setup/:locationId/:requirementId"
                element={<SalesTaxSetupView />}
              />
              <Route path="forms" element={<AutomationWrapper />} />
              <Route path="automation/*" element={<AutomationView />} />
              <Route
                path="ddc/pending-changes?/:formId"
                element={<FormPreviewView />}
              />
              <Route path="*" element={<NotFound />} />
            </Route>
          </Route>
        </Route>
      </Route>
    </Route>
  </Route>
);

if (IS_EMBEDDED) {
  routes = (
    <>
      {embeddedSharedWithAppRoutes}
      {embeddedOnlyRoutes}
    </>
  );
}

export const router = sentryCreateBrowserRouter(
  createRoutesFromElements(routes),
);

const App = () => <RouterProvider router={router} />;

export default App;
