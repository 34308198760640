import { RegisterOptions, useFormContext } from 'react-hook-form';

type StandaloneRadioGroupProps = {
  disabled?: boolean;
  options: Array<{
    label: string;
    value: string;
  }>;
  name: string;
  reactFormConfig?: RegisterOptions;
};

export const StandaloneRadioGroup = ({
  disabled = false,
  options,
  name,
  reactFormConfig,
}: StandaloneRadioGroupProps) => {
  const { register } = useFormContext();
  return (
    <div className="divide-y-2 divide-sage-100 bg-white">
      {options.map((option) => (
        <div key={option.value}>
          <label className="cursor-pointer text-lg font-bold">
            <div className="flex items-center gap-x-4 px-6 py-3">
              <input
                className="cursor-pointer"
                type="radio"
                value={option.value}
                disabled={disabled}
                {...register(name, reactFormConfig)}
              />
              {option.label}
            </div>
          </label>
        </div>
      ))}
    </div>
  );
};
