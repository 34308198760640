import { FunctionComponent } from 'react';

type ErrorProps = {
  heading: string;
  body: string;
};

export const Error: FunctionComponent<ErrorProps> = ({ heading, body }) => {
  return (
    <div className="mt-4 flex max-w-6xl rounded border border-red-100 bg-red-50 px-4 py-3">
      <div className="stroke-current text-red-500">
        <svg
          className="mr-2 size-6"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
      </div>
      <div className="flex-1">
        <h2 className="font-medium text-red-900">{heading}</h2>
        <p className="text-sm text-red-800">{body}</p>
      </div>
    </div>
  );
};
