import { PlaceholderLink } from '@mosey/components/navigation/PlaceholderLink';
import { CategoryItem } from '../types';
import { PencilAltIcon } from '@heroicons/react/outline';
import { Link } from 'react-router-dom';
import { CompanyWidePolicyContent } from '@mosey/components/handbook/CompanyWidePolicyContent';

type CustomPolicyBlockProps = {
  item: CategoryItem;
};

export const CustomPolicyBlock = ({ item }: CustomPolicyBlockProps) => {
  const editPage = `/handbook/section/${item.sectionId}/categories/${item.slug}`;

  if (item.customPolicy) {
    return (
      <CompanyWidePolicyContent
        mainContent={item.customPolicy.content.main}
        action={
          <Link
            to={editPage}
            className="flex items-center gap-2 font-semibold text-teal-900"
          >
            <PencilAltIcon className="size-[18px]" />
            Edit
          </Link>
        }
      />
    );
  } else {
    return (
      <PlaceholderLink to={editPage} size="small">
        Customize policy
      </PlaceholderLink>
    );
  }
};
